import { Box, CircularProgress, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

import MainHeader from "../../components/MainHeader";
import CreateEditUsername from "../../components/CreateEditUsername";
import CreateEditCountry from "../../components/CreateEditCountry";
import AdSense from "../../components/AdSense";
import { useEffect } from "react";
import { assignContentCreator } from "../Settings/Creator";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthProvider";
import { useSession } from "../../context/SessionProvider";
import WorkoutSkeleton from "../../components/Skeleton";

const Root = () => {
  const { t } = useTranslation(["root"]);
  const session = useSession();

  const state = session?.state ?? null;
  const dispatch = session?.dispatch ?? null;
  const customer = state?.customer ?? null;

  console.log("session root render ", session);

  const { jwt } = useAuth();

  useEffect(() => {
    if (!state || customer?.creator_id) return;

    const referralCode = localStorage.getItem("referralCode");
    if (referralCode) {
      assignContentCreator(referralCode, false, session, true).then(() => {
        localStorage.removeItem("referralCode");
      });
    }
  }, [state, customer?.creator_id]);

  console.log("root state is: ", state);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100dvh",
        position: "fixed",
        padding: { xs: 0, md: 1 },
        gap: 1,
        display: "flex",
        flexDirection: {
          xs: "column-reverse", // Hide on extra small screens
          sm: "row", // Show as flex on medium screens and up
        },
      }}
    >
      <AdSense />
      <MainHeader />
      {!customer ? (
        <WorkoutSkeleton />
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: "1",
            borderRadius: "0.30rem",
            overflow: "scroll",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Outlet />
        </Box>
      )}
    </Box>
  );
};

export default Root;
