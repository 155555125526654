import { toast } from "sonner";
import { API_BASE_URL, validUuid } from ".";
import { workout } from "../vite-env";
import { routineWithUsr } from "../hooks/useRoutine";

export const fetchLikeRoutine = async ({
  jwt,
  routine_id,
  handleChangeLike,
}: {
  jwt: string | undefined | null;
  routine_id: string | undefined;
  handleChangeLike: (param1: boolean) => void;
}) => {
  if (!jwt) {
    return;
  }
  const myHeaders = new Headers();

  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  try {
    const res = await fetch(
      API_BASE_URL + "/routine/like/" + routine_id,
      requestOptions
    );
    if (!res.ok) {
      throw new Error("Like was not successfull");
    }

    console.log("likeroutine res", res);
  } catch (error) {
    console.log("the error is in fetch: " + error);
    if (handleChangeLike) {
      handleChangeLike(false);
    }
  }
};

export const fetchDislikeRoutine = async ({
  jwt,
  routine_id,
  handleChangeLike,
}: {
  jwt: string | undefined | null;
  routine_id: string | undefined;
  handleChangeLike: (param1: boolean) => void;
}) => {
  if (!jwt) {
    throw new Error("fetchDislikeRoutine jwt err" + jwt);
  }
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
  };

  try {
    await fetch(API_BASE_URL + "/routine/like/" + routine_id, requestOptions);
    console.log("fetchDislikeroutine");
  } catch (error) {
    console.log("the error is in fetch: " + error);
    if (handleChangeLike) {
      handleChangeLike(true);
    }
  }
};
export const fetchRoutineById = async ({
  jwt,
  routine_id,
}: {
  jwt: string | null;
  routine_id: string | undefined;
}): Promise<
  | {
      routine: routineWithUsr;
      workouts: workout[];
      liked: boolean;
    }
  | undefined
> => {
  if (!routine_id || !validUuid(routine_id)) {
    toast.error(
      `The routine id "${routine_id}" is incorrect. The correct format is: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.`
    );
    return;
  }
  if (!jwt || !routine_id) {
    return undefined;
  }

  console.log("fetchWorkoutById ");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  try {
    const response = await fetch(
      API_BASE_URL + "/routine/" + routine_id,
      requestOptions
    );
    console.log("response: fetchWorkoutById", response);
    const result = await response.json();
    console.log("response: fetchWorkoutById result", result);

    // ✅ Strictly type the object
    const objFin: {
      routine: routineWithUsr;
      workouts: workout[];
      liked: boolean;
    } = {
      routine: result.data.routine,
      workouts: result.data.workouts,
      liked: result.data.liked,
    };

    return objFin;
  } catch (error) {
    console.log("the error is in fetch: " + error);
  }
};

export const deleteRoutine = async ({
  jwt,
  routine_id,
}: {
  jwt: string | undefined;
  routine_id: string | undefined;
}) => {
  if (!routine_id || !validUuid(routine_id)) {
    return toast.error(
      `The routine id "${routine_id}" is incorrect. The correct format is: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.`
    );
  }
  console.log("DELETE ROUTINE EXECUTED ");
  if (!jwt || !routine_id) {
    return;
  }

  console.log("delete routine by id ");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
  };

  try {
    const res = await fetch(
      API_BASE_URL + "/routine/" + routine_id,
      requestOptions
    );
    if (!res.ok) {
      throw new Error("Delete routine error");
    }
  } catch (error) {
    console.log("Delete routine error: " + error);
    throw new Error("Delete routine error");
  }
};

export const fetchLastRoutineExecution = async ({
  customer_id,
}: {
  customer_id: string | undefined;
}) => {
  if (!customer_id) {
    return;
  }
  console.log("fetchLastRoutineExecution ");

  try {
    console.log("pre fetch");
    const res = await fetch(
      API_BASE_URL + "/readLastRoutineExecution/" + customer_id
    );
    console.log("res fetchLastRoutineExecution", res);
    if (!res.ok) {
      throw new Error("read was not successfull");
    }
    const resJSon = await res.json();
    console.log("res resJSonfetchLastRoutineExecution", resJSon);
    if ("routine_id" in resJSon.data) {
      return resJSon.data;
    }
    return null;
  } catch (error) {
    console.error("the error is in fetch readLastRoutineExecution: " + error);
  }
};
