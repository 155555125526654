import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { fetchAuthSession, signOut } from "@aws-amplify/auth";

// Define the authentication state
interface AuthState {
  token: string | null;
  expiresAt: number | null;
  isAdmin: boolean | null;
}

type AuthAction =
  | {
      type: "SET_TOKEN";
      token: string;
      expiresAt: number;
      isAdmin: boolean | null;
    }
  | { type: "LOGOUT" };

// Reducer to manage authentication state
const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.token,
        expiresAt: action.expiresAt,
        isAdmin: action.isAdmin,
      };
    case "LOGOUT":
      signOut();
      return { token: null, expiresAt: null, isAdmin: null };
    default:
      return state;
  }
};

// Create AuthContext
const AuthContext = createContext<{
  jwt: string | null;
  getValidToken: () => Promise<string | null>;
  isAuthenticated: boolean;
  isAdmin: boolean | null;
} | null>(null);

// Provide authentication state globally
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, {
    token: null,
    expiresAt: null,
    isAdmin: null,
  });

  const refreshAuthToken = useCallback(async (): Promise<string | null> => {
    try {
      const session = await fetchAuthSession();
      const newToken = session.tokens?.idToken?.toString() || null;
      const expiresAt = session.tokens?.idToken?.payload.exp
        ? session.tokens.idToken.payload.exp * 1000
        : null;

      // Extract groups from token payload
      const groups =
        (session.tokens?.idToken?.payload["cognito:groups"] as string[]) || [];

      const isAdmin = groups.includes("admin");

      if (newToken && expiresAt) {
        dispatch({ type: "SET_TOKEN", token: newToken, expiresAt, isAdmin });
        return newToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      dispatch({ type: "LOGOUT" });
    }
    return null;
  }, []);

  const getValidToken = useCallback(async (): Promise<string | null> => {
    const now = Date.now();
    const { token, expiresAt } = state;

    if (token && expiresAt && expiresAt - now > 3 * 60 * 1000) {
      return token;
    }
    return await refreshAuthToken();
  }, [state, refreshAuthToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (state.expiresAt && state.expiresAt - Date.now() < 3 * 60 * 1000) {
        refreshAuthToken();
      }
    }, 60 * 1500);

    return () => clearInterval(interval);
  }, [state.expiresAt, refreshAuthToken]);

  useEffect(() => {
    refreshAuthToken();
  }, [refreshAuthToken]);

  return (
    <AuthContext.Provider
      value={{
        jwt: state.token,
        getValidToken,
        isAuthenticated: !!state.token,
        isAdmin: state.isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
