import { toast } from "sonner";
import { API_BASE_URL } from ".";

export const fetchLastCustomerWeight = async ({ jwt, customer_id }) => {
  if (!jwt || !customer_id) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_BASE_URL}/readCustomerWeightRecords/${customer_id}`,
      requestOptions
    );
    const result = await response.json();

    console.log("fetchLastCustomerWeight", result);
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCreateCustomerWeight = async ({ jwt, weight }) => {
  if (!jwt) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    weight,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API_BASE_URL}/createCustomerWeightRecord`,
      requestOptions
    );
    if (res.ok) {
      return true;
    } else {
      const error = await res.json();
      toast.error(error.errMsg);
      return false;
    }
  } catch (error) {
    console.error(error);
    toast.error(error);
  }
};
export const fetchLastCustomerKcal = async ({ jwt, customer_id }) => {
  if (!jwt || !customer_id) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  try {
    const response = await fetch(
      `${API_BASE_URL}/readCustomerKcalRecords/${customer_id}`,
      requestOptions
    );
    const result = await response.json();

    console.log("fetchLastCustomerKcal", result);
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCreateCustomerKcal = async ({ jwt, kcal }) => {
  if (!jwt) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    kcal,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_BASE_URL}/createCustomerKcalRecord`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("There was an error setting kcal.");
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const fetchLastCustomerDiet = async ({ jwt, customer_id }) => {
  if (!jwt || !customer_id) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_BASE_URL}/status/${customer_id}`,
      requestOptions
    );
    const result = await response.json();

    console.log("diet useCustomerDiet", result);
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCreateCustomerDiet = async ({ jwt, status }) => {
  if (!jwt) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    status,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_BASE_URL}/status`, requestOptions);
    const result = await response.json();
    console.log("diet", result);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const fetchLastCustomerWater = async ({ jwt, customer_id }) => {
  if (!jwt || !customer_id) {
    return;
  }

  console.log("fetchLastCustomerWater  ", jwt, customer_id);
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_BASE_URL}/readCustomerWaterRecords/${customer_id}`,
      requestOptions
    );
    const result = await response.json();
    console.log("fetchLastCustomerWater", result);
    return result.data;
  } catch (error) {
    console.error(" jwt, customer_id", error);
    console.log(" jwt, customer_id", jwt, customer_id);
  }
};

export const fetchCreateCustomerWater = async ({ jwt, liters }) => {
  if (!jwt) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    liters,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API_BASE_URL}/createCustomerWaterRecord`,
      requestOptions
    );
    if (res.ok) {
      toast.success("Water was updated succesfully.");
      return true;
    } else {
      const errJson = await res.json();
      toast.error(errJson.errMsg);
      return false;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.errMsg || "Water could not be updated.");
    return false;
  }
};
