import useSWR from "swr";
import { country } from "../vite-env";

export const countriesArr = [
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb5b",
    name: "afghanistan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb5c",
    name: "albania",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb5d",
    name: "algeria",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb5e",
    name: "andorra",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb5f",
    name: "angola",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb60",
    name: "antiguaandbarbuda",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb61",
    name: "argentina",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb62",
    name: "armenia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb63",
    name: "australia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb64",
    name: "austria",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb65",
    name: "azerbaijan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb66",
    name: "bahamas",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb67",
    name: "bahrain",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb68",
    name: "bangladesh",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb69",
    name: "barbados",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb6a",
    name: "belarus",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb6b",
    name: "belgium",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb6c",
    name: "belize",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb6d",
    name: "benin",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb6e",
    name: "bhutan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb6f",
    name: "bolivia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb70",
    name: "bosniaandherzegovina",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb71",
    name: "botswana",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb72",
    name: "brazil",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb73",
    name: "brunei",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb74",
    name: "bulgaria",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb75",
    name: "burkinafaso",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb76",
    name: "burundi",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb77",
    name: "caboverde",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb78",
    name: "cambodia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb79",
    name: "cameroon",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb7a",
    name: "canada",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb7b",
    name: "centralafricanrepublic",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb7c",
    name: "chad",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb7d",
    name: "chile",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb7e",
    name: "china",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb7f",
    name: "colombia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb80",
    name: "comoros",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb81",
    name: "Congodemocraticrepublicofthe",
  },

  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb83",
    name: "costarica",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb84",
    name: "croatia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb85",
    name: "cuba",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb86",
    name: "cyprus",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb87",
    name: "czechrepublic",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb88",
    name: "cenmark",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb89",
    name: "djibouti",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb8a",
    name: "dominica",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb8b",
    name: "dominicanrepublic",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb8c",
    name: "ecuador",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb8d",
    name: "egypt",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb8e",
    name: "elsalvador",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb8f",
    name: "equatorialguinea",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb90",
    name: "eritrea",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb91",
    name: "estonia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb92",
    name: "eswatini",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb93",
    name: "ethiopia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb94",
    name: "fiji",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb95",
    name: "finland",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb96",
    name: "france",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb97",
    name: "gabon",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb98",
    name: "gambia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb99",
    name: "georgia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb9a",
    name: "germany",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb9b",
    name: "ghana",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb9c",
    name: "greece",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb9d",
    name: "grenada",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb9e",
    name: "guatemala",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fb9f",
    name: "guinea",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fba0",
    name: "guineabissau",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fba1",
    name: "gguyana",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fba2",
    name: "haiti",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fba3",
    name: "honduras",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fba4",
    name: "hungary",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fba5",
    name: "iceland",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fba6",
    name: "india",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fba7",
    name: "indonesia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fba8",
    name: "iran",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fba9",
    name: "iraq",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbaa",
    name: "ireland",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbab",
    name: "israel",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbac",
    name: "italy",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbad",
    name: "jamaica",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbae",
    name: "japan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbaf",
    name: "jordan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbb0",
    name: "kazakhstan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbb1",
    name: "kenya",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbb2",
    name: "kiribati",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbb3",
    name: "koreanorth",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbb4",
    name: "koreasouth",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbb5",
    name: "kosovo",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbb6",
    name: "kuwait",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbb7",
    name: "kyrgyzstan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbb8",
    name: "laos",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbb9",
    name: "latvia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbba",
    name: "lebanon",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbbb",
    name: "lesotho",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbbc",
    name: "liberia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbbd",
    name: "libya",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbbe",
    name: "liechtenstein",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbbf",
    name: "lithuania",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbc0",
    name: "luxembourg",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbc1",
    name: "madagascar",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbc2",
    name: "malawi",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbc3",
    name: "malaysia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbc4",
    name: "maldives",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbc5",
    name: "mali",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbc6",
    name: "malta",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbc7",
    name: "marshallislands",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbc8",
    name: "mauritania",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbc9",
    name: "mauritius",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbca",
    name: "mexico",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbcb",
    name: "micronesia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbcc",
    name: "moldova",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbcd",
    name: "monaco",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbce",
    name: "mongolia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbcf",
    name: "montenegro",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbd0",
    name: "morocco",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbd1",
    name: "mozambique",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbd2",
    name: "myanmar",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbd3",
    name: "namibia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbd4",
    name: "nauru",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbd5",
    name: "nepal",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbd6",
    name: "netherlands",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbd7",
    name: "newzealand",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbd8",
    name: "nicaragua",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbd9",
    name: "niger",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbda",
    name: "nigeria",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbdb",
    name: "northmacedonia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbdc",
    name: "norway",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbdd",
    name: "oman",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbde",
    name: "pakistan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbdf",
    name: "palau",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbe0",
    name: "panama",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbe1",
    name: "papuanewguinea",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbe2",
    name: "paraguay",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbe3",
    name: "peru",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbe4",
    name: "philippines",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbe5",
    name: "poland",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbe6",
    name: "portugal",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbe7",
    name: "qatar",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbe8",
    name: "romania",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbe9",
    name: "russia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbea",
    name: "rwanda",
  },

  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbec",
    name: "saintlucia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbed",
    name: "saintvincentandthegrenadines",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbee",
    name: "samoa",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbef",
    name: "sanmarino",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbf0",
    name: "saotomeandprincipe",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbf1",
    name: "saudiarabia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbf2",
    name: "senegal",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbf3",
    name: "serbia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbf4",
    name: "seychelles",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbf5",
    name: "sierraleone",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbf6",
    name: "singapore",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbf7",
    name: "slovakia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbf8",
    name: "slovenia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbf9",
    name: "solomonislands",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbfa",
    name: "somalia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbfb",
    name: "southafrica",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbfc",
    name: "southsudan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbfd",
    name: "spain",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbfe",
    name: "srilanka",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fbff",
    name: "sudan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc00",
    name: "suriname",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc01",
    name: "sweden",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc02",
    name: "switzerland",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc03",
    name: "syria",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc04",
    name: "taiwan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc05",
    name: "tajikistan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc06",
    name: "tanzania",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc07",
    name: "thailand",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc08",
    name: "timorleste",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc09",
    name: "togo",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc0a",
    name: "tonga",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc0b",
    name: "trinidadandtobago",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc0c",
    name: "tunisia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc0d",
    name: "turkey",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc0e",
    name: "turkmenistan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc0f",
    name: "tuvalu",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc10",
    name: "uganda",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc11",
    name: "ukraine",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc12",
    name: "unitedarabemirates",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc13",
    name: "unitedkingdom",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc14",
    name: "unitedstates",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc15",
    name: "uruguay",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc16",
    name: "uzbekistan",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc17",
    name: "vanuatu",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc18",
    name: "vaticancity",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc19",
    name: "venezuela",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc1a",
    name: "vietnam",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc1b",
    name: "yemen",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc1c",
    name: "zambia",
  },
  {
    country_id: "018f2fe1-186e-749d-ba45-2f0f9cb9fc1d",
    name: "zimbabwe",
  },
];

/* const fetchCountries = () => {
  try {
     const response = await fetch(`${API_BASE_URL}/country/readCountries`);
    const result = await response.json(); 
    console.log("fetchCountries: ", result.data);
    return countriesArr;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch countries.");
  }
}; 

const useCountries = () => {
  const { data, error, isLoading } = useSWR<country[]>(
    { url: "countries" },
    () => fetchCountries()
  );

  console.log("data creator balance: ", data);

  return {
    countries: data,
    isLoading,
    error,
  };
};

export default useCountries;
*/
