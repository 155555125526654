import useSWR from "swr";
import {
  fetchCreateCustomerDiet,
  fetchLastCustomerDiet,
} from "../utils/customerUtils";
import { useState } from "react";
import { Diet } from "../vite-env";
import { useAuth } from "../context/AuthProvider";

const useCustomerDiet = ({
  customer_id,
}: {
  customer_id: string | undefined;
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const { jwt } = useAuth();

  const { data, isLoading, error, mutate, isValidating } = useSWR<
    {
      created_at: string;
      customer_status_id: string;
      owner_id: string;
      status: Diet;
      time: number;
      value: null;
    }[]
  >({ url: "customerDiet", customer_id, jwt }, () =>
    fetchLastCustomerDiet({ jwt, customer_id })
  );

  const handleSubmitDiet = async (newDiet: Diet) => {
    setIsUpdating(true);
    const bool = await fetchCreateCustomerDiet({
      jwt,
      status: newDiet,
    });
    if (bool) {
      await mutate(); // Refresh data after successful Diet submission
    }
    setIsUpdating(false);
  };

  return {
    customerDietArr: data,
    isLoading,
    isValidating,
    error,
    isUpdating,
    handleSubmitDiet,
  };
};

export default useCustomerDiet;
