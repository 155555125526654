import useSWR from "swr";
import { fetchLastRoutineExecution } from "../utils/routineUtils";
import { useMemo } from "react";

const useLastRoutineExecution = ({
  customer_id,
}: {
  customer_id: string | undefined;
}) => {
  const { data, isLoading, error, mutate } = useSWR(
    { url: `fetchLastRoutineExecution`, customer_id },
    fetchLastRoutineExecution
  );

  const lastRoutineExecutionMemoized = useMemo(() => data, [data]);

  return {
    lastRoutineExecution: lastRoutineExecutionMemoized,
    lastRoutineExecutionIsLoading: isLoading,
    error,
    mutate,
  };
};

export default useLastRoutineExecution;
