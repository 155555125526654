import React from "react";
import { Box, Button, Typography, Avatar, Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
const Roadmap = () => {
  const { t } = useTranslation(["landing"]);

  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center" mb={4}>
        {t("roadmap_title")}
      </Typography>
      <Timeline position="alternate">
        {/* Lanzamiento Web */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" fontWeight="bold">
              {t("launch_web_date")}
            </Typography>
            <Typography>{t("launch_web_desc")}</Typography>
          </TimelineContent>
        </TimelineItem>

        {/* Colaboración con Creadores */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="grey" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" fontWeight="bold">
              {t("collaboration_date")}
            </Typography>
            <Typography>{t("collaboration_desc")}</Typography>
          </TimelineContent>
        </TimelineItem>

        {/* Agregar Macros y Dietas */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="grey" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" fontWeight="bold">
              {t("macros_date")}
            </Typography>
            <Typography>{t("macros_desc")}</Typography>
          </TimelineContent>
        </TimelineItem>

        {/* Lanzamiento App Móvil */}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="grey" />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" fontWeight="bold">
              {t("mobile_app_date")}
            </Typography>
            <Typography>{t("mobile_app_desc")}</Typography>
            <Box
              display="flex"
              justifyContent="center"
              gap={2}
              mt={2}
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src="./googleplay.webp"
                sx={{ width: { xs: 100, sm: 140 } }}
              />
              <Box
                component="img"
                src="./applestore.webp"
                sx={{ width: { xs: 100, sm: 140 } }}
              />
            </Box>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Container>
  );
};

const LandingPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["landing"]);

  // Function to switch languages
  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === "es" ? "en" : "es");
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100dvh",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        overflowY: "auto",
        position: "relative",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          width: "100%",
          padding: { xs: "0.5rem", md: "1rem" },
          backgroundColor: "#f5f5f5",
          boxShadow: "0 25px 50px -12px rgba(0,0,0,0.1)",
          flexDirection: { xs: "column", md: "row" },
        }}
        component="header"
      >
        {/* Logo and Title */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            height: "130px",
            justifyContent: { xs: "center", md: "start" },
          }}
        >
          <Avatar
            alt="Gym Obsessive Logo"
            src="/gymobsessivelogo.png"
            sx={{
              width: 80,
              height: 80,
              display: { xs: "block", md: "inline" },
            }}
          />
          <Typography
            variant="h4"
            component="h1"
            fontWeight="bold"
            color="primary"
            textAlign={{ xs: "center", md: "left" }}
          >
            Gym Obsessive
          </Typography>
        </Box>

        {/* Buttons Container */}
        <Box sx={{ display: "flex", gap: 2 }}>
          {/* Language Toggle Button */}
          <Button
            variant="outlined"
            color="primary"
            onClick={toggleLanguage}
            sx={{
              width: 90,
              height: "50px",
            }}
          >
            {i18n.language === "es" ? "Español" : "English"}
          </Button>

          {/* App Button */}
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: 140,
              height: "50px",
            }}
            component={Link}
            to="/app"
          >
            App
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          minHeight: "70vh",
          backgroundImage: "url('/landingfin.avif')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "center", md: "flex-start" }, // Izquierda en PC, centro en móvil
          textAlign: "center",
          padding: "2rem",
        }}
      >
        {/* Card con blur */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "550px",
            background: "rgba(255, 255, 255, 0.2)", // Fondo semitransparente
            backdropFilter: "blur(10px)", // Efecto de blur
            borderRadius: "16px",
            padding: "3rem",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)", // Sombra negra
            justifyContent: "center",
            alignContent: "space-around",
            gap: 2,
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            color="white"
            sx={{
              textShadow: "3px 3px 8px rgba(0, 0, 0, 0.7)",
              fontSize: { xs: "2rem", md: "3rem" },
            }}
          >
            {t("title")}
          </Typography>

          <Typography
            variant="h5"
            fontWeight="bold"
            color="white"
            sx={{
              textShadow: "2px 2px 6px rgba(0, 0, 0, 0.7)",
              marginTop: "1rem",
              fontSize: { xs: "1.2rem", md: "1.5rem" },
            }}
          >
            {t("subtitle")}
          </Typography>

          {/* Botón para ir a la app */}
          <Button
            variant="contained"
            color="primary"
            href="/app"
            sx={{ padding: 2 }}
            onClick={() => navigate("/app")}
          >
            {t("app_redirect")} <FitnessCenterIcon sx={{ marginLeft: 1 }} />
          </Button>
        </Box>
      </Box>

      {/* Main Section */}
      <Container
        maxWidth="md"
        sx={{
          textAlign: "center",
          mt: 4,
          color: "#333",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 4,
          px: { xs: 2, md: 0 },
        }}
      >
        {/* Roadmap */}
        <Roadmap />

        {/* Island 1 */}
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            padding: "2rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.15)",
            borderRadius: "12px",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            fontWeight="bold"
            color="primary"
            gutterBottom
            fontSize={{ xs: "1.8rem", md: "2.125rem" }}
          >
            {t("text1")}
          </Typography>
          <Typography
            variant="body1"
            component="p"
            textAlign="center"
            color="#333"
            fontSize={{ xs: "1rem", md: "1.25rem" }}
          >
            {t("text2")}
          </Typography>
        </Box>

        {/* Island 2 */}
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            padding: "2rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.15)",
            borderRadius: "12px",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            fontWeight="bold"
            color="primary"
            gutterBottom
            fontSize={{ xs: "1.8rem", md: "2.125rem" }}
          >
            {t("text3")}
          </Typography>

          <Typography
            variant="body1"
            paragraph
            fontSize={{ xs: "1rem", md: "1.25rem" }}
          >
            {t("text4")}
          </Typography>

          <Typography
            variant="body1"
            paragraph
            fontSize={{ xs: "1rem", md: "1.25rem" }}
          >
            {t("text5")}
          </Typography>
        </Box>
      </Container>

      {/* Footer */}
      <Box
        component="footer"
        sx={{
          padding: "1rem",
          textAlign: "center",
          width: "100%",
          backgroundColor: "#f5f5f5",
          boxShadow: "0 25px 50px 0px rgba(0,0,0,0.1)",
          position: "sticky",
          bottom: 0,
        }}
      >
        <Typography variant="body2" component="p">
          {t("text6")}
          <Link to="/privacy-policy" style={{ textDecoration: "underline" }}>
            Privacy Policy.
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default LandingPage;
