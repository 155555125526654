import React, { useEffect } from "react";
import { useSession } from "../../context/SessionProvider";

const AdSense = () => {
  const session = useSession();

  console.log("session:  home", session);

  const state = session?.state;
  const customer = state?.customer;

  useEffect(() => {
    if (customer?.premium === false) {
      function addClickHandler() {
        if (!document.body.onclick) {
          // Check if there's no existing click handler
          document.body.onclick = function () {
            window.open("https://stiftais.top/4/8374366", "_blank");
            document.body.onclick = null; // Disable after first click
          };
          console.log("Click handler added!");
        }
      }

      // Run every 2.5 minutes (120,000 ms)
      const interval = setInterval(addClickHandler, 150000);

      let script3;
      setTimeout(() => {
        script3 = document.createElement("script");
        script3.type = "text/javascript";
        script3.src = "/executePublicity3.js";
        script3.async = true;
        script3.onload = () => console.log("Publicity script3 loaded 2");
        script3.onerror = () =>
          console.error("Failed to load publicity script 3");
        document.body.appendChild(script3);
      }, 10000);

      let script1;
      setTimeout(() => {
        script1 = document.createElement("script");
        script1.type = "text/javascript";
        script1.src = "/executePublicity1.js";
        script1.async = true;
        script1.onload = () => console.log("Publicity script1 loaded 1");
        script1.onerror = () =>
          console.error("Failed to load publicity script1 1");
        document.body.appendChild(script1);
      }, 20000);

      let script2;
      setTimeout(() => {
        script2 = document.createElement("script");
        script2.type = "text/javascript";
        script2.src = "/executePublicity2.js";
        script2.async = true;
        script2.onload = () => console.log("Publicity script2 loaded 2");
        script2.onerror = () =>
          console.error("Failed to load publicity script 2");
        document.body.appendChild(script2);
      }, 30000);
    }
  }, [customer?.premium]);
};

export default AdSense;
