import { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { toast } from "sonner";
import { API_BASE_URL } from "../../utils";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthProvider";
import { useSession } from "../../context/SessionProvider";

const CreateEditUsername = ({
  username: usr,
  optional,
}: {
  username: string | null;
  optional: boolean;
}) => {
  const { t } = useTranslation(["compCreateEditUsername"]);

  const session = useSession();
  const dispatch = session?.dispatch;

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(usr || "");
  const { jwt } = useAuth();

  const updateUsername = async (newUsername: string) => {
    setLoading(true);
    if (
      newUsername &&
      newUsername.length > 0 &&
      newUsername.length < 41 &&
      newUsername !== usr &&
      dispatch
    ) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + jwt);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({ username: newUsername });

      const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
      };

      const promise = new Promise((resolve, reject) => {
        fetch(`${API_BASE_URL}/customer/username`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            resolve(result.data[0].username);
            /*             session.change.username(newUsername);
             */ dispatch({
              type: "UPDATE_CUSTOMER",
              field: "username",
              value: newUsername,
            });
          })
          .catch((error) => {
            reject(error);
          });
      });

      toast.promise(promise, {
        loading: t("edit"),
        success: (updatedUsername) => t("suc", { updatedUsername }),
        error: (err) => `Error: ${JSON.stringify(err?.errMsg || err)}.`,
      });
    } else {
      toast.error(`The username "${newUsername}" is invalid.`);
    }
    setLoading(false);
  };

  if (optional) {
    return null;
  }

  return (
    <Box
      sx={{
        borderRadius: "0.30rem",
        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
        p: 1,
      }}
    >
      <Typography variant="h5" fontWeight="bold" marginBottom={2}>
        {t("usr")}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        <TextField
          error={!username}
          required
          disabled={loading}
          sx={{ width: "300px" }}
          label={t("usr")}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Button
          onClick={() => updateUsername(username)}
          sx={{
            px: 2,
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            color: "white",
            background: "black",
            ":hover": { background: "black", transform: "scale(0.95)" },
          }}
        >
          {t("save")}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditUsername;
