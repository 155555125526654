import React from "react";
import { Typography, Link, Box } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <Box p={3}>
      {/* Make the box clickable and set a cursor pointer */}
      <Box
        sx={{ display: "flex", cursor: "pointer" }}
        onClick={() => navigate("/app")} // Make sure this is a valid function
      >
        <ArrowBackIcon fontSize="large" />
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy GymObsessive
        </Typography>

        <Typography variant="body1" paragraph>
          We value your privacy. This Privacy Policy explains how we collect,
          use, and protect your personal information when you use our app.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          - <strong>Email Address:</strong> We collect and store your email
          address when you use our app.
        </Typography>
        <Typography variant="body1" paragraph>
          - <strong>Usage Data:</strong> We collect information provided by you
          while using the app, which may include personal details or
          preferences.
        </Typography>

        <Typography variant="h6" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          - We use your email address to communicate with you and provide
          relevant updates or notifications.
        </Typography>
        <Typography variant="body1" paragraph>
          - The information you provide helps us improve the app’s performance
          and user experience.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We take data security seriously and implement appropriate technical
          measures to protect your information. However, no method of
          transmission over the Internet or electronic storage is completely
          secure.
        </Typography>

        <Typography variant="body1" paragraph>
          For more information or any privacy concerns, please contact us at:{" "}
          <Link href="mailto:gymobsessive@gmail.com">
            gymobsessive@gmail.com
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
