import useSWR from "swr";
import { useState, useCallback, useMemo } from "react";
import { API_BASE_URL } from "../utils";
import type { customer } from "../vite-env"; // Adjust the path to where you exported the types
import { useAuth } from "../context/AuthProvider";
import { useSession } from "../context/SessionProvider";

const readUser = async ({
  user_id,
  jwt,
}: {
  user_id: string | undefined;
  jwt: string | null;
}) => {
  if (!jwt || !user_id) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  try {
    const res = await fetch(`${API_BASE_URL}/customer/${user_id}`);
    const resJson = await res.json();
    console.log(
      "read user json; ",
      Array.isArray(resJson.data) && resJson.data.length > 0,
      resJson
    );
    if (Array.isArray(resJson.data) && resJson.data.length > 0) {
      return resJson.data[0];
    } else {
      throw new Error("The user was not found.");
    }
  } catch (error) {
    console.error("Error caught: ", error);
    throw new Error("The user was not found.");
  }
};

const followUser = async ({
  user_id,
  jwt,
  logged_in_id,
}: {
  user_id: string | null;
  jwt: string | null;
  logged_in_id: string | null;
}) => {
  if (logged_in_id === user_id) {
    throw new Error("You can't follow yourself.");
  }
  if (!jwt || !user_id) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  try {
    const res = await fetch(
      `${API_BASE_URL}/customerFollow/${user_id}`,
      requestOptions
    );
    const resJson = await res.json();
    if (resJson.status === "ok") {
      return resJson.data;
    } else {
      throw new Error("The user was not found. Status not ok");
    }
  } catch (error) {
    console.error("Error caught: ", error);
    throw new Error("The user was not found.");
  }
};

const unfollowUser = async ({
  user_id,
  jwt,
  logged_in_id,
}: {
  user_id: string | null;
  jwt: string | null;
  logged_in_id: string | null;
}) => {
  if (logged_in_id === user_id) {
    throw new Error("You can't unfollow yourself.");
  }
  if (!jwt || !user_id) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  try {
    const res = await fetch(
      `${API_BASE_URL}/customerUnfollow/${user_id}`,
      requestOptions
    );
    const resJson = await res.json();
    if (resJson.status === "ok") {
      return resJson.data;
    }
  } catch (error) {
    console.error("Error caught: ", error);
    throw new Error("The user was not found.");
  }
};

const readFollowUser = async ({
  user_id,
  jwt,
  logged_in_id,
}: {
  user_id: string | null;
  jwt: string | null;
  logged_in_id: string | null;
}) => {
  if (logged_in_id === user_id) {
    return;
  }
  if (!jwt || !user_id) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  try {
    const res = await fetch(`${API_BASE_URL}/readFollowCustomer/${user_id}`, {
      headers: myHeaders,
    });
    const resJson = await res.json();
    console.log("readFollow User: ", resJson);
    if (resJson.data) {
      return resJson.data.isFollowing;
    } else {
      throw new Error("The user was not found.");
    }
  } catch (error) {
    console.error("Error caught: ", error);
    throw new Error("The user was not found.");
  }
};

const useUser = ({ user_id }: { user_id: string | undefined | null }) => {
  const { jwt } = useAuth();
  const [likeDisabled, setLikeDisabledUi] = useState(false);

  const session = useSession();

  const dispatch = session?.dispatch;
  const customer = session?.state.customer;
  const logged_in_id = customer?.customer_id ?? null;

  const { data, isLoading, error, mutate } = useSWR<customer>(
    { url: `user`, user_id, logged_in_id, jwt },
    () => readUser({ jwt, user_id })
  );

  const readFollowUserRes = useSWR(
    { url: `userFollow`, user_id, logged_in_id, jwt },
    () => readFollowUser({ jwt, user_id, logged_in_id })
  );

  const handleFollowUser = async () => {
    if (!data || !dispatch) return;
    setLikeDisabledUi(true);
    /*
      TODO 1) function to transform a complex user into a simple row in order to insert it in allTables 
    */
    dispatch({ type: "ADD_TABLE", item: data });
    await followUser({ user_id, jwt, logged_in_id });
    readFollowUserRes.mutate(true);
    mutate((prevData) => {
      if (!prevData) return prevData; // Handle case where prevData is null

      return {
        ...prevData,
        followers_count: (prevData.followers_count ?? 0) + 1,
      };
    });
    setTimeout(() => setLikeDisabledUi(false), 5000);
  };
  const handleUnfollowUser = async () => {
    if (!data || !dispatch) return; // Prevents calling functions with null data

    setLikeDisabledUi(true);

    /*     session.deleteAllTable(data.customer_id, "customer_id");
     */
    dispatch({ type: "DELETE_TABLE", id: data.customer_id });

    await unfollowUser({ user_id, jwt, logged_in_id });
    readFollowUserRes.mutate(false);
    mutate((prevData) => {
      if (!prevData) return prevData; // Handle case where prevData is null

      return {
        ...prevData,
        followers_count: (prevData.followers_count ?? 0) - 1,
      };
    });
    setTimeout(() => setLikeDisabledUi(false), 5000);
  };

  const isTheLoggedInUser = useMemo(
    () => logged_in_id && user_id && logged_in_id === user_id,
    [logged_in_id, user_id]
  );
  const isFollowing = useMemo(
    () => readFollowUserRes.data,
    [readFollowUserRes.data]
  );

  return {
    user: data,
    isLoading,
    error,
    mutate,
    likeDisabled,
    setLikeDisabledUi,
    handleFollowUser,
    handleUnfollowUser,
    isTheLoggedInUser,
    isFollowing,
    readFollowResLoading: readFollowUserRes.isLoading,
    readFollowError: readFollowUserRes.error,
    readFollowMutate: readFollowUserRes.mutate,
  };
};

export default useUser;
