import useSWR from "swr";
import { fetchLastCustomerKcal } from "../utils/customerUtils";
import { useMemo, useRef } from "react";
import { toast } from "sonner";
import { API_BASE_URL } from "../utils";
import { useAuth } from "../context/AuthProvider";
import { useSession } from "../context/SessionProvider";

const useCustomerKcal = ({
  customer_id,
}: {
  customer_id: string | undefined;
}) => {
  const isUpdating = useRef(false);

  const { jwt } = useAuth();

  console.log("jwt use auth: ", jwt);
  const session = useSession();
  const dispatch = session?.dispatch;

  const { data, isLoading, error, mutate, isValidating } = useSWR(
    { url: "customerKcal", customer_id, jwt }, // Exclude jwt from key
    () => fetchLastCustomerKcal({ jwt, customer_id }) // Pass jwt separately
  );

  const handleGenerateKcal = async () => {
    isUpdating.current = true;

    const promise = new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/generateKcalNumber`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "There was an error updating calories requirement."
            );
          }
          return response.json();
        })
        .then((resjson) => {
          console.log("res json kcal: ", resjson);
          const fincal = resjson.data.targetKcal;
          resolve(fincal);
          mutate(); // Refresh data after successful Kcal submission
          /*           session.change.kcal(fincal); // Assuming session handles activity level updates
           */ dispatch!({
            type: "UPDATE_CUSTOMER",
            field: "kcal",
            value: fincal,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });

    toast.promise(promise, {
      loading: "Updating calories requirement...",
      success: (updatedStatus) =>
        `Calories requirement successfully updated to ${updatedStatus}.`,
      error: (err) =>
        `Error updating calories requirement: ${JSON.stringify(
          err?.errMsg || err
        )}.`,
    });

    //setIsUpdating(false);
  };

  const customerKcalArr = useMemo(() => data, [data]);

  console.log("customerKcal hook called ", data);
  return {
    customerKcalArr,
    isLoading,
    isValidating,
    error,
    isUpdating,
    handleGenerateKcal,
  };
};

export default useCustomerKcal;
