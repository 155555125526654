import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const WorkoutSkeleton = () => {
  return (
    <Box sx={{ p: 2, width: "100vw", height: "100vh" }}>
      {/* Header Skeleton */}
      <Box display={"flex"} width={"100%"} gap={3} my={3}>
        <Skeleton variant="rectangular" height={120} width={120} />
        <Box display="flex" flexDirection={"column"} width={"100%"}>
          <Skeleton variant="text" width={"80%"} height={40} />
          <Skeleton variant="text" width={"80%"} height={30} />
        </Box>
      </Box>
      <Skeleton variant="rectangular" width={"75%"} height={200} />

      {/* Exercise Table Skeleton */}
      {[...Array(5)].map((_, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Skeleton
            variant="rectangular"
            width={80}
            height={80}
            sx={{ borderRadius: 1 }}
          />
          <Box sx={{ ml: 2, flex: 1 }}>
            <Skeleton variant="text" width="60%" height={20} />
            <Skeleton variant="text" width="40%" height={15} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default WorkoutSkeleton;
