import useSWR from "swr";
import {
  fetchDislikeWorkout,
  fetchLikeWorkout,
  fetchWorkoutById,
} from "../utils/workoutUtils";
import { useCallback, useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { exercise, workout } from "../vite-env";
import { useSession } from "../context/SessionProvider";

export interface workoutWithUsr extends workout {
  username: string;
  verified: boolean;
}

interface ISeriesDoneExercise {
  weight: number;
  reps: number;
  trackTime: number;
  totalTime: number;
}

interface IExecutionExercise {
  sets: number;
  reps: { min: number; max: number };
  rir: number;
  seriesDone: ISeriesDoneExercise[];
  seriesTrack: number;
  details: string;
}

interface ITimeExercise {
  countdown: number;
  countdownMax: number;
  duration: number;
  durationMax: number;
  rest: number;
  restMax: number;
  totalTime: number;
  trackTime: number;
}

export interface IExerciseWithWorkoutDetails extends exercise {
  execution: IExecutionExercise;
  time: ITimeExercise;
}

const useWorkout = ({ workout_id }: { workout_id: string | undefined }) => {
  const [likeDisabled, setLikeDisabledUi] = useState(false);

  const { jwt } = useAuth();

  const session = useSession();

  const dispatch = session?.dispatch;

  const { data, isLoading, error, mutate } = useSWR<{
    exercises: IExerciseWithWorkoutDetails[];
    workout: workoutWithUsr;
    liked: boolean;
  } | null>({ url: `workout`, workout_id }, () =>
    fetchWorkoutById({ jwt, workout_id })
  );

  const handleChangeLike = useCallback(
    async (add: boolean) => {
      if (data && data.workout) {
        const currentLikesCount = data.workout.likes_count || 0;
        const updatedLikesCount = add
          ? currentLikesCount + 1
          : currentLikesCount - 1;

        const newObj = {
          ...data,
          workout: {
            ...data.workout,
            likes_count: updatedLikesCount,
          },
          liked: add,
        };

        await mutate(newObj, false);
      } else {
        console.error("Workout data is not available.");
      }
    },
    [data, mutate]
  );

  const handleLikeWorkout = async () => {
    if (!likeDisabled && data && dispatch) {
      await handleChangeLike(true);
      setLikeDisabledUi(true);

      /*       userSession.addAllTable(data.workout);
       */ dispatch({ type: "ADD_TABLE", item: data.workout });

      fetchLikeWorkout({
        jwt,
        workout_id,
        handleChangeLike,
      });
      setTimeout(() => {
        setLikeDisabledUi(false);
      }, 2000);
    }
  };

  const handleDislikeWorkout = async () => {
    if (!likeDisabled && data && dispatch) {
      await handleChangeLike(false);
      setLikeDisabledUi(true);

      dispatch({ type: "DELETE_TABLE", id: data.workout.workout_id });

      fetchDislikeWorkout({
        jwt,
        workout_id,
        handleChangeLike,
      });
      setTimeout(() => {
        setLikeDisabledUi(false);
      }, 2000);
    }
  };

  return {
    data,
    isLoading,
    error,
    mutate,
    likeDisabled,
    setLikeDisabledUi,
    handleChangeLike,
    handleLikeWorkout,
    handleDislikeWorkout,
  };
};

export default useWorkout;
