import useSWR from "swr";
import { useCallback, useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { API_BASE_URL } from "../utils";
import { payment } from "../vite-env";

interface CreatorBalanceResponse {
  balance: number;
  unpaidCommissions: IcustomerWCode[];
}

interface IcustomerWCode extends payment {
  username: string;
  verified: boolean;
  status: string;
}

const fetchCreatorBalance = async (jwt: string | null) => {
  console.log("fetchCreatorBalance: ", jwt);
  if (!jwt) return;

  const response = await fetch(`${API_BASE_URL}/pay/myCreatorBalance`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch creator balance");
  }

  const resJSON = await response.json();
  return {
    balance: resJSON.data.balance,
    unpaidCommissions: resJSON.data.unpaidCommissions,
  };
};

const useCreatorBalance = () => {
  const { jwt } = useAuth();

  const { data, error, isLoading } = useSWR<CreatorBalanceResponse | undefined>(
    { url: "creatorBalance", jwt },
    () => fetchCreatorBalance(jwt)
  );

  console.log("data creator balance: ", data);

  return {
    balance: data?.balance ?? 0,
    unpaidCommissions: data?.unpaidCommissions ?? [],
    isLoading,
    error,
  };
};

export default useCreatorBalance;
