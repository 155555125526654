import React from "react";
import { useSession } from "../../context/SessionProvider";
import { Box, Typography } from "@mui/material";
import CreateEditUsername from "../CreateEditUsername";
import CreateEditCountry from "../CreateEditCountry";
import WorkoutSkeleton from "../Skeleton";
import { useTranslation } from "react-i18next";

const UsernameCountryMiddleware = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation(["root"]);
  const session = useSession();

  const state = session?.state ?? null;
  const customer = state?.customer ?? null;

  if (!customer) {
    return <WorkoutSkeleton />;
  }

  if (customer?.username && customer?.country_id) {
    return children;
  } else {
    return (
      <Box px={1} sx={{ height: "100%" }}>
        <Typography variant="h3" fontWeight="bold" mb={3}>
          {t("completeInfo")}
        </Typography>
        <CreateEditUsername
          optional={!!customer?.username}
          username={customer?.username}
        />
        <CreateEditCountry
          optional={!!customer?.country_id}
          country_id={customer?.country_id}
        />
      </Box>
    );
  }
};

export default UsernameCountryMiddleware;
