import { Box, Typography } from "@mui/material";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import AssessmentIcon from "@mui/icons-material/Assessment";
import HomeCard from "../../components/HomeCard";
import useCustomerWeight from "../../hooks/useCustomerWeight";
import useCustomerWater from "../../hooks/useCustomerWater";
import useCustomerExercisesExecution from "../../hooks/useCustomerExercisesExecution";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { useNavigate } from "react-router-dom";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import useCustomerDiet from "../../hooks/useCustomerDiet";
import useLastRoutineExecution from "../../hooks/useMyLastRoutine";
import NotWorkedOutYet from "../../components/NotWorkedOutYet";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import useCustomerKcal from "../../hooks/useCustomerKcal";
import RoutineCard from "../../components/RoutineCard";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import FreeTrialButton from "../../components/FreeTrialButton";
import { useTranslation } from "react-i18next";
import WorkoutSkeleton from "../../components/Skeleton";
import { useSession } from "../../context/SessionProvider";
import { customer } from "../../vite-env";

export function formatNumberString(numberString: string): string {
  // Convert the string to a number
  const number = parseFloat(numberString);

  // Check if the number is an integer
  if (Number.isInteger(number)) {
    return number.toString(); // Return the number as a string without decimals
  } else {
    return number.toFixed(2); // Return the number with two decimal places
  }
}

export function calcularCambioPorcentual(
  antiguo: number,
  nuevo: number
): number {
  if (antiguo === 0) throw new Error("El valor antiguo no puede ser 0");
  return ((nuevo - antiguo) / antiguo) * 100;
}

const Home = ({ user }: { user?: customer | undefined }) => {
  const { t } = useTranslation(["home"]);

  const navigate = useNavigate();

  const session = useSession();

  console.log("session:  home", session);

  const state = session?.state;
  const customer = state?.customer;

  const userId = user ? user.customer_id : customer?.customer_id;

  const { lastRoutineExecution, lastRoutineExecutionIsLoading } =
    useLastRoutineExecution({
      customer_id: userId,
    });

  console.log("lastRoutineExecution : ", lastRoutineExecution);
  const { customerWeightArr } = useCustomerWeight({
    customer_id: userId,
  });

  const { customerKcalArr } = useCustomerKcal({
    customer_id: userId,
  });

  const { customerWaterArr } = useCustomerWater({
    customer_id: userId,
  });

  const { customerExercisesArr, settingsCustomerExercisesArr } =
    useCustomerExercisesExecution({
      customer_id: userId,
    });

  console.log("customerExercisesArr ", customerExercisesArr);
  const { customerDietArr } = useCustomerDiet({
    customer_id: userId,
  });

  console.log("Home render");

  if (
    lastRoutineExecutionIsLoading ||
    !settingsCustomerExercisesArr ||
    !customerWeightArr ||
    !customerWaterArr ||
    !session ||
    !userId ||
    !customerKcalArr
  ) {
    return <WorkoutSkeleton />;
  }
  console.log("session post return ", session);
  console.log("settingsCustomerExercisesArr home:_ ", customerExercisesArr);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        gap: 3,
        overflowY: "auto",
        p: 2,
      }}
      className="scrollbar-hide"
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: 2,
          justifyContent: "space-around",
          flexWrap: "wrap",
          boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
          borderRadius: "0.30rem",
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FlashOnIcon fontSize="large" />
          <Typography variant="h6" fontWeight={"bold"}>
            {user ? user.energy : customer?.energy}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ":hover": {
              cursor: "pointer",
            },
            gap: 1,
          }}
          onClick={() => {
            navigate(`/app/diet/${userId}`);
          }}
        >
          {(user ? user.status : customer?.status) === "Bulk" && (
            <Box
              component={"img"}
              src="/bear.png"
              alt="Bear for Bulk"
              sx={{ width: "40px", height: "40px" }}
            />
          )}
          {(user ? user.status : customer?.status) === "Definition" && (
            <Box
              component={"img"}
              src="/flamenco.png"
              alt="Flamenco for Definition"
              sx={{ width: "40px", height: "40px" }}
            />
          )}
          {(user ? user.status : customer?.status) === "Recomposition" && (
            <Box
              component={"img"}
              src="/chamaleon.png"
              alt="Chameleon for Recomposition"
              sx={{ width: "40px", height: "40px" }}
            />
          )}
          <Typography
            variant="h6"
            fontWeight={"bold"}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            {(user ? user.status : customer?.status) || "-"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ":hover": {
              cursor: "pointer",
            },
            gap: 0.5,
          }}
          onClick={() => {
            navigate(`/app/weight/${userId}`);
          }}
        >
          <MonitorWeightIcon fontSize="large" />
          <Typography variant="h6" fontWeight={"bold"}>
            {(user ? user.weight : customer?.weight) || "-"}
            Kg
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ":hover": {
              cursor: "pointer",
            },
            gap: 0.5,
          }}
          onClick={() => {
            navigate(`/app/kcal/${userId}`);
          }}
        >
          <RamenDiningIcon fontSize="large" />
          <Typography variant="h6" fontWeight={"bold"}>
            {(user ? user.kcal : customer?.kcal) || "-"}Kcal
          </Typography>
        </Box>
        {!user && !customer?.premium && <FreeTrialButton />}
      </Box>

      {lastRoutineExecution ? (
        <RoutineCard routine={lastRoutineExecution} />
      ) : (
        <Box>
          <Typography variant="h5" fontWeight="bold" mt={2}>
            {t("routine")}
          </Typography>
          <HomeCard Icon={FitnessCenterIcon} name={t("routine")} to={`/app`} />
        </Box>
      )}
      <Box>
        <Typography variant="h5" fontWeight="bold" mt={2}>
          {t("metrics")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: 1,
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <HomeCard
            Icon={AssessmentIcon}
            customerData={customerWeightArr}
            name={t("weigth")}
            to={`/app/weight/${userId}`}
          />
          <HomeCard
            Icon={WaterDropIcon}
            customerData={customerWaterArr}
            name={t("water")}
            to={`/app/water/${userId}`}
          />
          <HomeCard
            Icon={RestaurantIcon}
            customerData={customerDietArr}
            name={t("diet")}
            to={`/app/diet/${userId}`}
            grapType="stage"
          />
          <HomeCard
            Icon={AssessmentIcon}
            customerData={customerKcalArr}
            name={t("kcal")}
            to={`/app/kcal/${userId}`}
          />
        </Box>
      </Box>
      {customerExercisesArr && Object.keys(customerExercisesArr).length > 0 && (
        <Box sx={{ overflowX: "auto" }}>
          <Typography variant="h5" fontWeight="bold" mt={2}>
            {t("progress")}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridAutoFlow: "column", // Ensure items flow in a horizontal direction
              gridTemplateRows: "repeat(2, 1fr)", // Two rows of equal height
              gridAutoColumns: "minmax(180px, 1fr)", // Ensure each column has a minimum width
              gap: 1,
              mt: 1,
              p: 1,
            }}
          >
            {customerExercisesArr &&
              Object.keys(customerExercisesArr).map((exercise) => {
                const arrActualWeight =
                  customerExercisesArr[exercise][
                    settingsCustomerExercisesArr[exercise]
                  ];
                console.log(
                  "asddd",
                  arrActualWeight[0].reps,
                  arrActualWeight[arrActualWeight.length - 1].reps
                );
                /* TODO: THE  */
                const percentageChange = calcularCambioPorcentual(
                  arrActualWeight[0].value,
                  arrActualWeight[arrActualWeight.length - 1].value
                );

                console.log("percentageChange ", percentageChange);

                return (
                  <HomeCard
                    exercise={arrActualWeight[0]}
                    to={`/app/exercise/${arrActualWeight[0].exercise_id}`}
                    key={exercise}
                    isExercise={true}
                    name={
                      exercise.slice(0, 16) +
                      " " +
                      (settingsCustomerExercisesArr[exercise] > 0
                        ? formatNumberString(
                            settingsCustomerExercisesArr[exercise]
                          ) + "Kg"
                        : "")
                    }
                    customerData={arrActualWeight}
                    percentageChange={
                      percentageChange ? percentageChange : false
                    }
                  />
                );
              })}
          </Box>
        </Box>
      )}

      {/* TODO: IMPLEMENT MORE FAMOUS WORKOUTS INSTEAD OF THIS  */}
      {!user &&
        customerExercisesArr &&
        Object.keys(customerExercisesArr).length < 1 && <NotWorkedOutYet />}
    </Box>
  );
};

export default Home;
