import useSWR from "swr";
import {
  fetchCreateCustomerWeight,
  fetchLastCustomerWeight,
} from "../utils/customerUtils";
import { useMemo, useState } from "react";
import { toast } from "sonner";
import { useAuth } from "../context/AuthProvider";
import { useSession } from "../context/SessionProvider";

const useCustomerWeight = ({
  customer_id,
}: {
  customer_id: string | undefined;
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const session = useSession();
  const dispatch = session?.dispatch;

  const { jwt } = useAuth();

  const { data, isLoading, error, mutate, isValidating } = useSWR(
    { url: "customerWeight", customer_id, jwt },
    () => fetchLastCustomerWeight({ jwt, customer_id })
  );

  const handleSubmitWeight = async (newWeight: number) => {
    setIsUpdating(true);
    const bool = await fetchCreateCustomerWeight({
      jwt,
      weight: newWeight,
    });
    if (bool) {
      await mutate(); // Refresh data after successful weight submission
      toast.success(`Weight successfully updated to ${newWeight} Kg.`);
      /*       session.change.weight(newWeight);
       */ dispatch!({
        type: "UPDATE_CUSTOMER",
        field: "weight",
        value: newWeight,
      });
    }
    setIsUpdating(false);
  };

  const customerWeightArr = useMemo(() => data, [data]);

  return {
    customerWeightArr,
    isLoading,
    isValidating,
    error,
    isUpdating,
    handleSubmitWeight,
  };
};

export default useCustomerWeight;
