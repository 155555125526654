import { fetchAuthSession } from "aws-amplify/auth";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  ReactNode,
} from "react";
import {
  API_BASE_URL,
  CreatedAndLikedItem,
  fetchCustomer,
  fetchReadAllCreatedAndLikedTables,
  formatItem,
  ICustomerWithCountryName,
} from "../utils";
import { useAuth } from "./AuthProvider";
import { customer } from "../vite-env";
import { exerciseWithUsr } from "../hooks/useExercise";
import { routineWithUsr } from "../hooks/useRoutine";
import { workoutWithUsr } from "../hooks/useWorkout";
import { toast } from "sonner";

type SessionState = {
  customer: ICustomerWithCountryName | null;
  allTables: CreatedAndLikedItem[] | null;
  email?: string | null;
  picture?: string | null;
  triggerKcalUpdate?: boolean;
};

export type allDataTypes =
  | exerciseWithUsr
  | routineWithUsr
  | customer
  | workoutWithUsr;

export type SessionAction =
  | { type: "SET_SESSION"; payload: SessionState }
  | {
      type: "UPDATE_CUSTOMER";
      field: keyof ICustomerWithCountryName;
      value: string | number | null;
    }
  | { type: "ADD_TABLE"; item: allDataTypes }
  | { type: "DELETE_TABLE"; id: string }
  | { type: "TRIGGER_KCAL_UPDATE"; value: boolean }
  | { type: "REFETCH_TABLES" };

const sessionReducer = (
  state: SessionState,
  action: SessionAction
): SessionState => {
  switch (action.type) {
    case "SET_SESSION":
      return action.payload;
    case "UPDATE_CUSTOMER": {
      const newState = {
        ...state,
        customer: {
          ...(state.customer as ICustomerWithCountryName),
          [action.field]: action.value,
        },
        triggerKcalUpdate: [
          "birthdate",
          "gender",
          "status",
          "height",
          "weight",
          "activity_level",
        ].includes(action.field),
      };
      return newState;
    }
    case "ADD_TABLE":
      return {
        ...state,
        allTables: [formatItem(action.item), ...(state.allTables || [])],
      };
    case "DELETE_TABLE":
      return {
        ...state,
        allTables:
          state.allTables &&
          state.allTables.filter((item) => item.id !== action.id),
      };
    case "TRIGGER_KCAL_UPDATE":
      return { ...state, triggerKcalUpdate: action.value };
    default:
      return state;
  }
};

const fetchUserSession = async (
  userId: string,
  dispatch: React.Dispatch<SessionAction>
) => {
  console.log("Fetching user session");

  const [session, customer, allTables] = await Promise.all([
    fetchAuthSession(),
    fetchCustomer(userId),
    fetchReadAllCreatedAndLikedTables(userId),
  ]);

  dispatch({
    type: "SET_SESSION",
    payload: {
      customer,
      allTables,
      email: (session.tokens?.idToken?.payload?.email as string) || null,
      picture: (session.tokens?.idToken?.payload?.picture as string) || null,
    },
  });
};

const generateKcal = async (
  state: SessionState,
  dispatch: React.Dispatch<SessionAction>,
  jwt: string
) => {
  if (!jwt) {
    throw new Error("JWT required.");
  }
  if (
    state &&
    state.customer &&
    state.customer.status &&
    state.customer.birthdate &&
    state.customer.gender &&
    state.customer.height &&
    state.customer.activity_level &&
    state.customer.weight
  ) {
    try {
      const response = await fetch(`${API_BASE_URL}/generateKcalNumber`, {
        method: "POST",
        headers: { Authorization: `Bearer ${jwt}` },
      });
      if (!response.ok) throw new Error("Error updating calories requirement.");

      const resjson = await response.json();
      console.log("Updated Kcal: ", resjson);
      dispatch({
        type: "UPDATE_CUSTOMER",
        field: "kcal",
        value: resjson.data.targetKcal,
      });
      toast.success("Kcal : " + resjson.data.targetKcal);
    } catch (error) {
      console.error("Kcal generation error: ", error);
    } finally {
      dispatch({ type: "TRIGGER_KCAL_UPDATE", value: false });
    }
  }
};

const SessionContext = createContext<{
  state: SessionState;
  dispatch: React.Dispatch<SessionAction>;
  refetchCreatedAndLikedItems: () => void;
} | null>(null);

type SessionProviderProps = { children: ReactNode; user: { userId: string } };

const SessionProvider = ({ children, user }: SessionProviderProps) => {
  const { jwt } = useAuth();

  const [state, dispatch] = useReducer(sessionReducer, {
    customer: null,
    allTables: null,
  });

  useEffect(() => {
    if (user?.userId) {
      fetchUserSession(user.userId, dispatch);
    }
  }, [user.userId]);

  useEffect(() => {
    if (state.triggerKcalUpdate && jwt) {
      setTimeout(() => generateKcal(state, dispatch, jwt), 1000);
    }
  }, [state, jwt]);

  const refetchCreatedAndLikedItems = async () => {
    if (!user.userId) return;

    console.log("Refetching created and liked items...");

    try {
      const allTables = await fetchReadAllCreatedAndLikedTables(user.userId);

      dispatch({
        type: "SET_SESSION",
        payload: { ...state, allTables }, // Use current state and update allTables
      });
    } catch (error) {
      console.error("Error refetching created and liked items: ", error);
    }
  };

  return (
    <SessionContext.Provider
      value={{ state, dispatch, refetchCreatedAndLikedItems }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;

export const useSession = () => useContext(SessionContext);
