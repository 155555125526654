import useSWR from "swr";
import { useAuth } from "../../context/AuthProvider";
import { withdraw_request } from "../../vite-env";
import { API_BASE_URL } from "../../utils";

export const readWithdrawRequests = async ({ jwt }: { jwt: string | null }) => {
  if (!jwt) return;

  console.log("Fetching withdrawal requests");

  const headers = new Headers();
  headers.append("Authorization", "Bearer " + jwt);

  try {
    const res = await fetch(`${API_BASE_URL}/pay/paypal/readWithdrawBalance`, {
      method: "GET",
      headers,
    });

    if (!res.ok) {
      throw new Error("Failed to fetch withdrawal requests");
    }

    const { data } = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching withdrawal requests:", error);
  }
};

const useWithdrawRequest = () => {
  const { jwt } = useAuth();

  const { data, isLoading, error, mutate } = useSWR<withdraw_request[]>(
    { url: "readWithdrawRequests", jwt },
    () => readWithdrawRequests({ jwt })
  );

  return {
    withdrawRequests: data,
    isLoading,
    error,
    mutate,
  };
};

export default useWithdrawRequest;
