import { createChart, ColorType, AreaSeries } from "lightweight-charts";
import React, { useEffect, useRef } from "react";

const TradingGraphComponentStatus = (props) => {
  const {
    phases = [
      { time: 1627689600, status: "Bulk" },
      { time: 1627948800, status: "Definition" },
      { time: 1628121600, status: "Recomposition" },
    ],
    colors: { backgroundColor = "white", textColor = "#000000" } = {},
    rightPriceScale = false,
    leftPriceScale = false,
    timeScaleVisible = true,
  } = props;

  const chartContainerRef = useRef();

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
        attributionLogo: false,
      },
      autoSize: false,
      rightPriceScale: { visible: rightPriceScale },
      leftPriceScale: { visible: leftPriceScale },
      timeScale: { visible: timeScaleVisible },
    });

    chart.timeScale().fitContent();

    const getPhaseEndTime = (index) => {
      if (index + 1 < phases.length) {
        return phases[index + 1].time;
      }
      return Math.floor(Date.now() / 1000);
    };

    const phaseColors = {
      Bulk: "rgba(255, 0, 0, 0.8)",
      Definition: "rgba(255, 0, 128, 0.5)",
      Recomposition: "rgba(0, 153, 51, 0.5)",
    };

    phases.forEach((phase, index) => {
      const end = getPhaseEndTime(index);
      const backgroundSeries = chart.addSeries(AreaSeries, {
        lineColor: phaseColors[phase.status] || "rgba(0, 0, 0, 0.5)",
        topColor: phaseColors[phase.status] || "rgba(0, 0, 0, 0.3)",
        bottomColor: "transparent",
      });
      backgroundSeries.setData([
        { time: phase.time, value: 1 },
        { time: end, value: 1 },
      ]);
    });

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
  }, [backgroundColor, textColor, phases]);

  return (
    <div ref={chartContainerRef} style={{ height: "100%", width: "100%" }} />
  );
};

export function TradingGraphStages(props) {
  return <TradingGraphComponentStatus {...props} />;
}
