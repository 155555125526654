import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import "./config/i18next"; // Your i18n setup file
import i18next from "i18next";

import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@aws-amplify/ui-react/styles.css";
import Root from "./routes/Root";
import Home from "./routes/Home";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import AuthStyle from "./AuthStyle";

import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";

import theme from "./theme";
import amplifyconfig from "./amplifyconfiguration.json";
import { Amplify } from "aws-amplify";
import { Authenticator, Flex, Heading, Image } from "@aws-amplify/ui-react";

import { Box, CircularProgress, ThemeProvider } from "@mui/material";
import SessionProvider from "./context/SessionProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { toast, Toaster } from "sonner";
import { SWRConfig } from "swr";

import LandingPage from "./routes/LandingPage";
import NotFound from "./routes/NotFound";
import PrivacyPolicy from "./routes/privacy-policy";
import { AuthProvider } from "./context/AuthProvider";
import WorkoutExecutionProvider from "./context/WorkoutProvider";

import * as Sentry from "@sentry/react";
import UsernameCountryMiddleware from "./components/UsernameCountryMiddleware";

const isProduction = import.meta.env.VITE_ENVIRONMENT === "production";
// RUN SENTRY ONLY IN PRODUCTION isProduction && Sentry.init(

if (isProduction) {
  Sentry.init({
    dsn: "https://c39795fc4cb7f5276bb65da19be5eca1@o4508996289429504.ingest.us.sentry.io/4508996291002368",
  });
}

const AdminWithdraw = lazy(() => import("./routes/AdminWithdraw"));
const AdminDashboard = lazy(() => import("./routes/AdminDashboard"));

const Search = lazy(() => import("./routes/Search"));
const Exercise = lazy(() => import("./routes/Excersise"));
const User = lazy(() => import("./routes/User"));
const Workout = lazy(() => import("./routes/Workout"));
const Weight = lazy(() => import("./routes/Weight"));
const YourLibrary = lazy(() => import("./routes/YourLibrary"));
const Top50WorldPopularWorkouts = lazy(
  () => import("./routes/Top50WorldPopularWorkouts")
);
const Top50WorldPopularWorkoutsCountry = lazy(
  () => import("./routes/Top50WorldPopularWorkoutsCountry")
);
const Routine = lazy(() => import("./routes/Routine"));
const Diet = lazy(() => import("./routes/Diet"));
const Kcal = lazy(() => import("./routes/Kcal"));
const Creator = lazy(() => import("./routes/Settings/Creator"));
const Personal = lazy(() => import("./routes/Settings/Personal"));

// Less accessed routes
const UpdateSettings = lazy(() => import("./routes/Settings"));
const Water = lazy(() => import("./routes/Water"));
const Create = lazy(() => import("./routes/Create"));
const CreateRoutine = lazy(() => import("./routes/Create/Routine"));
const CreateWorkout = lazy(() => import("./routes/Create/Workout"));
const CreateExercise = lazy(() => import("./routes/Create/Exercise"));
const Premium = lazy(() => import("./routes/Premium"));

Amplify.configure(amplifyconfig);

// Set Amplify translations
I18n.putVocabularies(translations);

// Function to sync Amplify language with i18next
const syncI18nWithAmplify = () => {
  I18n.setLanguage(i18next.language);
};

// Wait for i18next to initialize before syncing
i18next.init().then(() => {
  syncI18nWithAmplify();
});

// Listen for i18next language changes
i18next.on("languageChanged", (lng) => {
  I18n.setLanguage(lng);
});

function ProtectedCom({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isRedirected, setIsRedirected] = useState(false); // Prevent multiple redirects

  if (
    location.pathname !== "/app" &&
    !sessionStorage.getItem("redirectUrl") &&
    !isRedirected
  ) {
    sessionStorage.setItem("redirectUrl", location.pathname + location.search);
  }

  useEffect(() => {
    // Extract the query parameters from the URL
    const params = new URLSearchParams(location.search);
    const errorDescription = params.get("error_description");
    if (errorDescription) {
      // Decode the URL-encoded error description
      const decodedError = decodeURIComponent(errorDescription);
      toast.error(decodedError.split("error")[1]);
    }

    // Handle referral code
    const referralCode = params.get("ref");
    if (referralCode) {
      // Save the referral code to localStorage
      localStorage.setItem("referralCode", referralCode);
    }
  }, [location]);

  return (
    <AuthStyle>
      <Authenticator components={components} variation="modal">
        {({ user }) => {
          // Ensure that redirect happens only after successful login
          if (user && !isRedirected) {
            // Only redirect once after the user has logged in and hasn't been redirected yet
            const redirectUrl = sessionStorage.getItem("redirectUrl");
            sessionStorage.removeItem("redirectUrl"); // Clean up after redirect

            navigate(redirectUrl || "/app"); // Redirect to the stored URL
            setIsRedirected(true); // Set this flag so we don't redirect again
          }

          return (
            <SWRConfig
              value={{
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
                revalidateIfStale: false,
                fetcher: (resource, init) =>
                  fetch(resource, init).then((res) => res.json()),
              }}
            >
              <AuthProvider>
                <SessionProvider user={user}>
                  <WorkoutExecutionProvider>
                    {children}
                  </WorkoutExecutionProvider>
                </SessionProvider>
              </AuthProvider>
            </SWRConfig>
          );
        }}
      </Authenticator>
    </AuthStyle>
  );
}

export default ProtectedCom;

const SuspenseWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            width: "100%",
            height: "100dvh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={80} sx={{ color: "black" }} />
        </Box>
      }
    >
      {children}
    </Suspense>
  );
};

const router = createBrowserRouter([
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/",
    element: (
      <ThemeProvider theme={theme}>
        <SuspenseWrapper>
          <LandingPage />
        </SuspenseWrapper>
      </ThemeProvider>
    ),
  },
  {
    path: "adminDashboard", // This will match "/app"
    element: (
      <SuspenseWrapper>
        <ThemeProvider theme={theme}>
          <ProtectedCom>
            <AdminDashboard />
          </ProtectedCom>
        </ThemeProvider>
      </SuspenseWrapper>
    ),
  },
  {
    path: "adminWithdraw/:withdraw_request_id", // This will match "/app"
    element: (
      <ThemeProvider theme={theme}>
        <ProtectedCom>
          <SuspenseWrapper>
            <AdminWithdraw />
          </SuspenseWrapper>
        </ProtectedCom>
      </ThemeProvider>
    ),
  },
  {
    path: "/app",
    element: (
      <ThemeProvider theme={theme}>
        <ProtectedCom>
          <SuspenseWrapper>
            <Root />
          </SuspenseWrapper>
        </ProtectedCom>
      </ThemeProvider>
    ),

    children: [
      {
        path: "", // This will match "/app"
        element: (
          <SuspenseWrapper>
            <Home />
          </SuspenseWrapper>
        ),
      },

      {
        path: "diet/:customer_id", // This will match "/app/diet/:customer_id"
        element: (
          <SuspenseWrapper>
            <Diet />
          </SuspenseWrapper>
        ),
      },
      {
        path: "weight/:customer_id",
        element: (
          <SuspenseWrapper>
            <Weight />
          </SuspenseWrapper>
        ),
      },
      {
        path: "kcal/:customer_id",
        element: (
          <SuspenseWrapper>
            <Kcal />
          </SuspenseWrapper>
        ),
      },
      {
        path: "water/:customer_id",
        element: (
          <SuspenseWrapper>
            <Water />
          </SuspenseWrapper>
        ),
      },
      {
        path: "search",
        element: (
          <SuspenseWrapper>
            <Search />
          </SuspenseWrapper>
        ),
      },
      {
        path: "premium",
        element: (
          <SuspenseWrapper>
            <Premium />
          </SuspenseWrapper>
        ),
      },
      {
        path: "library",
        element: (
          <SuspenseWrapper>
            <YourLibrary />
          </SuspenseWrapper>
        ),
      },
      {
        path: "create",
        element: (
          <SuspenseWrapper>
            <UsernameCountryMiddleware>
              <Create />
            </UsernameCountryMiddleware>
          </SuspenseWrapper>
        ),
      },
      {
        path: "create/exercise",
        element: (
          <SuspenseWrapper>
            <UsernameCountryMiddleware>
              <CreateExercise />
            </UsernameCountryMiddleware>
          </SuspenseWrapper>
        ),
      },
      {
        path: "create/workout",
        element: (
          <SuspenseWrapper>
            <UsernameCountryMiddleware>
              <CreateWorkout />
            </UsernameCountryMiddleware>
          </SuspenseWrapper>
        ),
      },
      {
        path: "create/routine",
        element: (
          <SuspenseWrapper>
            <UsernameCountryMiddleware>
              <CreateRoutine />
            </UsernameCountryMiddleware>
          </SuspenseWrapper>
        ),
      },
      {
        path: "exercise/:exercise_id",
        element: (
          <SuspenseWrapper>
            <Exercise />
          </SuspenseWrapper>
        ),
      },
      {
        path: "workout/:workout_id",
        element: (
          <SuspenseWrapper>
            <Workout />
          </SuspenseWrapper>
        ),
      },
      {
        path: "routine/:routine_id",
        element: (
          <SuspenseWrapper>
            <Routine />
          </SuspenseWrapper>
        ),
      },
      {
        path: "user/:user_id",
        element: (
          <SuspenseWrapper>
            <UsernameCountryMiddleware>
              <User />
            </UsernameCountryMiddleware>
          </SuspenseWrapper>
        ),
      },
      {
        path: "Top50WorldPopularWorkouts",
        element: (
          <SuspenseWrapper>
            <Top50WorldPopularWorkouts />
          </SuspenseWrapper>
        ),
      },
      {
        path: "top50mostExecutedWorkoutsCountry/:country_id",
        element: (
          <SuspenseWrapper>
            <UsernameCountryMiddleware>
              <Top50WorldPopularWorkoutsCountry />
            </UsernameCountryMiddleware>
          </SuspenseWrapper>
        ),
      },
      {
        path: "settings",
        element: (
          <SuspenseWrapper>
            <UsernameCountryMiddleware>
              <UpdateSettings />
            </UsernameCountryMiddleware>
          </SuspenseWrapper>
        ),
        children: [
          {
            path: "creator",
            element: (
              <SuspenseWrapper>
                <UsernameCountryMiddleware>
                  <Creator />
                </UsernameCountryMiddleware>
              </SuspenseWrapper>
            ),
            /* children: [
              {
                path: "dashboard",
                element: (
                  <SuspenseWrapper>
                    <Dashboard />
                  </SuspenseWrapper>
                ),
              },
            ], */
          },
          {
            path: "personal",
            element: (
              <SuspenseWrapper>
                <UsernameCountryMiddleware>
                  <Personal />
                </UsernameCountryMiddleware>
              </SuspenseWrapper>
            ),
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

const components = {
  SignIn: {
    Header() {
      return (
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={10}
          //border="1px solid red"
        >
          <Image
            alt="Amplify logo"
            src="/gymobsessivelogo.png"
            width={60}
            height={60}
            /* border="1px solid red" */
          />

          <Heading
            marginInline={11}
            level={3}
            textAlign="center"
            alignSelf="center"
            /*             border="1px solid red"
             */
          >
            Gym Obsessive
          </Heading>
        </Flex>
      );
    },
  },
  SignUp: {
    Header() {
      return (
        <Flex
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={10}
        >
          <Image
            alt="Amplify logo"
            src="/gymobsessivelogo.png"
            width={60}
            height={60}
          />

          <Heading
            marginInline={11}
            level={3}
            textAlign="center"
            alignSelf="center"
            maxWidth="80%"
          >
            Gym Obsessive
          </Heading>
        </Flex>
      );
    },
    Footer() {
      return (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <span>
            By signing up, you agree{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener"
              style={{ color: "black" }}
            >
              {"Privacy Policy"}
            </a>
            .
          </span>
        </div>
      );
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <>
    <Toaster position="top-center" richColors closeButton expand={false} />
    <CssBaseline />
    <SuspenseWrapper>
      <RouterProvider router={router} />
    </SuspenseWrapper>
  </>
);
