import { API_BASE_URL } from ".";
import { customer_exercise_execution } from "../vite-env";

export const fetchExercisesCreatedAndLiked = async ({ jwt, setExercises }) => {
  console.log("fetchExercisesCreatedAndLiked jwt", jwt);

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      API_BASE_URL + "/exercise/likedandmyexercises",
      requestOptions
    );
    const result = await response.json();
    console.log("fetchExercisesCreatedAndLiked", result);
    if (setExercises) {
      setExercises(result.data);
    }
    return result.data;
  } catch (error) {
    console.error("fetchExercisesCreatedAndLiked err", error);
  }
};
export const fetchExercises = async ({ jwt, setExercises, exercisesIdArr }) => {
  const myHeaders = new Headers();

  myHeaders.append("Authorization", "Bearer " + jwt);
  myHeaders.append("Content-Type", "application/json");

  /* const raw = JSON.stringify({
      exercisesArr: [
        "018f2b0d-1dce-7d7e-bd26-24faf997441f",
        "018f2b0b-7084-7bf6-abe3-d528cc4ffd3a",
      ],
    }); */
  const raw = JSON.stringify({
    exercisesArr: exercisesIdArr,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(API_BASE_URL + "/exercises", requestOptions);
    const result = await response.json();
    if (setExercises) {
      setExercises(result.data);
    }
    console.log("fetchExercises", result);
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchExerciseLike = async ({ exercise_id, jwt, setLike }) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      API_BASE_URL + `/exercise/${exercise_id}/like`,
      requestOptions
    );

    const resJson = await res.json();
    console.log("likes red:", resJson);
    const bool = resJson.data[0].count === "1" ? true : false;
    console.log("bool ", bool);
    if (setLike) {
      console.log("setLike approved ");
      setLike(bool);
    }
    return bool;
  } catch (error) {
    console.error("fetchExerciseLike error", error);
    return null;
  }
};

export const deleteExercise = async ({ exercise_id, jwt }) => {
  if (!exercise_id || !jwt) {
    console.log("deleteExercise error", exercise_id, jwt);
    return;
  }

  console.log("deleteExercise:_", exercise_id);
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      API_BASE_URL + "/exercise/" + exercise_id,
      requestOptions
    );
    if (!res.ok) {
      throw new Error("Delete exercise error");
    }
  } catch (error) {
    console.error("Delete exercise error", error);
    throw new Error("Delete exercise error");
  }
};
export const fetchExercise = async ({ exercise_id, jwt }) => {
  if (!exercise_id || !jwt) {
    console.log("fetch exercise error", exercise_id || !jwt);
    return;
  }

  console.log("fetchExercise exercise_id:_", exercise_id);
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  try {
    const res = await fetch(
      API_BASE_URL + "/exercise/" + exercise_id,
      requestOptions
    );
    const resJson = await res.json();
    console.log("resJson ", resJson);
    if (resJson.status !== "ok") {
      throw new Error("not correct req");
    }
    console.log("exercise fet ",resJson.data)
    return resJson.data;
  } catch (error) {
    console.error(error);
  }
};
export const fetchExerciseEce = async ({ exercise_id, jwt }) => {
  if (!exercise_id || !jwt) {
    console.log("fetch exercise error", exercise_id || !jwt);
    return;
  }

  console.log("readEceOneExercise:_", exercise_id);
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      API_BASE_URL + "/readEceOneExercise/" + exercise_id,
      requestOptions
    );
    console.log("res: fetchExerciseEce", res);

    const resJson = await res.json();
    console.log("resJson fetchExerciseEce", resJson);
    if (resJson.status !== "ok") {
      throw new Error("not correct req");
    }
    return resJson.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchGiveLikeExecise = async ({
  jwt,
  exercise_id,
  handleChangeLike,
}) => {
  if (!jwt || !exercise_id) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_BASE_URL}/exercise/like/${exercise_id}`,
      requestOptions
    );
    await response.json();
    return true;
  } catch (error) {
    console.error("errorfetchGiveLikeExecise ", error);
    if (handleChangeLike) {
      handleChangeLike(false);
    }
  }
};

export const fetchGiveDislikeExecise = async ({
  jwt,
  exercise_id,
  handleChangeLike,
}) => {
  if (!jwt || !exercise_id) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_BASE_URL}/exercise/like/${exercise_id}`,
      requestOptions
    );
    console.log("fetchGiveDislikeExecise res", response);
    return true;
  } catch (error) {
    console.error("fetchGiveDislikeExecise err", error);
    if (handleChangeLike) {
      handleChangeLike(true);
    }
  }
};
export const fetchMyExercisesJwt = async (jwt) => {
  const myHeaders = new Headers();

  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const res = await fetch(API_BASE_URL + "/exercise", requestOptions);
    const resJson = await res.json();
    return resJson.data;
  } catch (error) {
    console.error("fetchMyExercisesJwt error", error);
    return null;
  }
};

export const fetchExercisesByCustomerId = async (userId) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_BASE_URL}/customer/${userId}/exercise`,
      requestOptions
    );
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error("fetchExercisesByCustomerId", error);
    return null;
  }
};

export const fetchLastEce = async ({ jwt, customer_id }) => {
  if (!jwt || !customer_id) {
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  interface exercise_execution_with_name extends customer_exercise_execution {
    name: string;
    photo_arr: string[];
    value: number;
    time: number;
  }

  try {
    const response = await fetch(
      `${API_BASE_URL}/readLastEce/${customer_id}`,
      requestOptions
    );
    const result = await response.json();
    const customerExercisesArr: Record<
      string,
      Record<string, exercise_execution_with_name[]>
    > = result.data;

    console.log("Fetcj exerciseECE ", customerExercisesArr);

    const settingsCustomerExercisesArr: Record<string, string> = {};

    if (customerExercisesArr) {
      Object.keys(customerExercisesArr).forEach((e) => {
        settingsCustomerExercisesArr[e] = Object.keys(
          customerExercisesArr[e]
        )[0];
      });
    }

    return { customerExercisesArr, settingsCustomerExercisesArr };
  } catch (error) {
    console.error(error);
  }
};

export const postLastEce = async ({ jwt, exercise_id, weight, reps }) => {
  if (!jwt) {
    throw new Error("jwt is required");
  }
  const myHeaders = new Headers();

  myHeaders.append("Authorization", "Bearer " + jwt);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    exercise_id,
    weight,
    reps,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${API_BASE_URL}/exerciseCustomerExecution`,
      requestOptions
    );
    const result = await response.json();
    console.log("postLastEce result: ", result);
  } catch (error) {
    console.error(error);
  }
};
