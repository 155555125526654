import { uuidv7 } from "uuidv7";
import { toast } from "sonner";
import type { customer } from "../vite-env";
import { allDataTypes } from "../context/SessionProvider";

// Configuración de PayPal, dependiendo del entorno (sandbox o producción)
const isProduction = import.meta.env.VITE_ENVIRONMENT === "production";

export const CLIENT_ID = isProduction
  ? import.meta.env.VITE_PAYPAL_PRODUCTION_CLIENT_ID
  : import.meta.env.VITE_PAYPAL_SANDBOX_CLIENT_ID;

export const SUBSCRIPTION_PLAN_NOTRIAL_ID = isProduction
  ? import.meta.env.VITE_PAYPAL_PRODUCTION_SUBSCRIPTION_PLAN_NOTRIAL_ID
  : import.meta.env.VITE_PAYPAL_SANDBOX_SUBSCRIPTION_PLAN_NOTRIAL_ID;

export const SUBSCRIPTION_PLAN_TRIAL_ID = isProduction
  ? import.meta.env.VITE_PAYPAL_PRODUCTION_SUBSCRIPTION_PLAN_TRIAL_ID
  : import.meta.env.VITE_PAYPAL_SANDBOX_SUBSCRIPTION_PLAN_TRIAL_ID;

export const validUuid = (uuid: string | undefined | null) => {
  if (!uuid || typeof uuid !== "string") return false;

  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return regex.test(uuid);
};

export const IMG_BASE_URL = "https://gymobsessiveimages.s3.amazonaws.com/";

export const API_BASE_URL = isProduction
  ? "https://ij4pz94j84.execute-api.us-east-1.amazonaws.com/prod"
  : "https://ldfhfia5bb.execute-api.us-east-1.amazonaws.com/dev";

export interface ICustomerWithCountryName extends customer {
  country_name: string;
}
export const fetchCustomer = async (
  userId: string
): Promise<ICustomerWithCountryName> => {
  try {
    const response = await fetch(`${API_BASE_URL}/customer/${userId}`);
    const responseJson = await response.json();

    return responseJson.data[0];
  } catch (error) {
    console.error("fetchCustomer error", error);
    throw new Error("There was en error finding the user. Try again later.");
  }
};

export const uploadFilesToS3 = async ({
  jwt,
  imgArr,
  vidArr,
  type,
}: {
  jwt: string | undefined | null;
  imgArr: File[];
  vidArr?: File[];
  type: "routine" | "exercise" | "workout";
}): Promise<
  | {
      photo_arr: (string | undefined)[];
      video_arr: (string | undefined)[] | null;
      id: string;
    }
  | undefined
> => {
  if (!jwt) {
    throw new Error("jwt required");
  }
  const myHeaders1 = new Headers();
  myHeaders1.append("Authorization", "Bearer " + jwt);
  myHeaders1.append("Content-Type", "application/json");

  const imgArrType = imgArr.map((img) => {
    return {
      type: img.type,
    };
  });

  const raw: Record<string, any> = {
    images: imgArrType,
    type: type,
  };

  if (vidArr) {
    raw.videos = vidArr.map((vid) => {
      return {
        type: vid.type,
      };
    });
  }

  console.log("raw :_", raw);

  const requestOptions1 = {
    method: "POST",
    headers: myHeaders1,
    body: JSON.stringify(raw),
  };
  let result1: {
    data: { imageUrls: string[]; videoUrls: string[]; id: string };
  } | null = null;

  try {
    const response = await fetch(
      API_BASE_URL + "/customer/upload",
      requestOptions1
    );
    console.log("upload s3 response: ", response);
    if (!response.ok) {
      const resJSON = await response.json();
      console.log("S3 json ", resJSON);
      throw new Error(resJSON.errMsg);
    }
    result1 = await response.json();

    console.log("result1", result1);
  } catch (error) {
    console.error("error at s3 upload", error);
    toast.error("error at s3 upload");
  }

  if (!result1?.data) {
    throw new Error("No link to upload images");
  }

  const imgProm = result1.data.imageUrls.map((url, index) => {
    console.log("map: img", url, index);
    const uploadExecution = async () => {
      console.log("imgArr[index].type: ", imgArr[index].type);
      const file = imgArr[index];
      console.log("file;: img", file);

      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": imgArr[index].type },
        body: file,
      };

      try {
        console.log("before imgProm");
        const response = await fetch(url, requestOptions);
        console.log("response imgProm", response);

        console.log("s3 uploaded img", response);
        return url.split("?")[0].split("amazonaws.com/")[1];
      } catch (error) {
        console.error("result1.data.imageUrls err", error);
      }
    };
    return uploadExecution();
  });
  console.log("img prom: ", imgProm);

  let vidProm: Promise<string | undefined>[] = [];
  if (result1.data.videoUrls && vidArr) {
    vidProm = result1.data.videoUrls.map((url, index) => {
      console.log("map: vid", url, index);

      const uploadExecution = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", vidArr[index].type);

        const file = vidArr[index];

        console.log("file;: vid", file);

        const requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: file,
        };

        try {
          const response = await fetch(url, requestOptions);
          console.log("s3 uploaded vid", response);
          return url.split("?")[0].split("amazonaws.com/")[1];
        } catch (error) {
          console.error(error);
        }
      };
      return uploadExecution();
    });
    console.log("vidProm:", vidProm);
  }
  const prom = [...imgProm, ...vidProm];
  console.log("prom ", prom);
  try {
    const res = await Promise.all(prom);
    console.log("Promise all res: ", res);
    const photo_arr = res.filter((url) => url && url.includes("image"));
    let video_arr: (string | undefined)[] | null = res.filter(
      (url) => url && url.includes("video")
    );
    if (video_arr.length === 0) {
      video_arr = null;
    }
    console.log("res fin uploading", res);
    return { photo_arr, video_arr, id: result1.data.id };
  } catch (error) {
    console.log("error fin uploading", error);
  }
};

export interface CreatedAndLikedItem {
  id: string;
  name: string;
  type: "exercise" | "workout" | "user" | "routine";
  owner_id: string;
  photo: string | null;
  username: string;
  verified: boolean;
  created_at: string; // ISO 8601 date format
}

export function formatItem(item: allDataTypes): CreatedAndLikedItem {
  if ("exercise_id" in item) {
    return {
      id: item.exercise_id,
      name: item.name,
      type: "exercise",
      owner_id: item.owner_id,
      photo: item.photo_arr?.[0] || null, // Select first photo if available
      username: item.username || "", // Ensure username is included
      verified: item.verified ?? false, // Ensure verification status is correct
      created_at: String(String(item.created_at)),
    };
  }

  if ("workout_id" in item) {
    return {
      id: item.workout_id,
      name: item.name,
      type: "workout",
      owner_id: item.owner_id,
      photo: item.photo_url || null,
      username: item.username || "", // Ensure username is included
      verified: item.verified ?? false, // Ensure verification status is correct
      created_at: String(item.created_at),
    };
  }

  if ("routine_id" in item) {
    return {
      id: item.routine_id,
      name: item.name,
      type: "routine",
      owner_id: item.owner_id,
      photo: item.photo_url || null,
      username: item.username || "", // Ensure username is included
      verified: item.verified ?? false, // Ensure verification status is correct
      created_at: String(item.created_at),
    };
  }

  if ("customer_id" in item) {
    return {
      id: item.customer_id,
      name: item.username || item.email, // Prefer username, fallback to email
      type: "user",
      owner_id: item.customer_id,
      photo: null,
      username: item.username || "", // Ensure username is included
      verified: item.verified ?? false, // Ensure verification status is correct
      created_at: String(item.created_at),
    };
  }

  throw new Error("Invalid item type provided");
}

export const fetchReadAllCreatedAndLikedTables = async (
  customer_id: string
): Promise<CreatedAndLikedItem[]> => {
  try {
    const response = await fetch(
      API_BASE_URL + "/readAllCreatedAndLikedTables/" + customer_id
    );
    const result = await response.json();
    console.log(result);
    console.log("fetchReadAllCreatedAndLikedTables" + JSON.stringify(result));
    return result.data.all;
  } catch (error) {
    console.error(error);
    throw new Error(
      "There was an error in the server. Please try again later."
    );
  }
};

export const fetchSearch = async ({
  setIsFetching,
  search,
  setRes,
  saveSeachInHistory,
}) => {
  const formatDate = (date) => {
    const options = { year: "2-digit", month: "2-digit", day: "2-digit" };
    return new Intl.DateTimeFormat("en-US", options)
      .format(date)
      .replace(/\//g, "-");
  };
  setIsFetching(true);
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    search,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  try {
    const response = await fetch(`${API_BASE_URL}/search`, requestOptions);
    const result = await response.json();
    if (setRes) {
      setRes(result.data);
    }
    saveSeachInHistory({
      id: uuidv7(),
      search: search,
      date: formatDate(new Date()),
    });

    return result.data;
  } catch (error) {
    console.error("err search ", error);
  } finally {
    setIsFetching(false);
  }
};

export function debounce({ cb, delay = 2000, setIsWritting, setIsFetching }) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    setIsWritting(true);
    timer = setTimeout(() => {
      setIsWritting(false);
      cb(...args);
    }, delay);
  };
}

type ValidationState = {
  state: boolean;
  message: string;
};

/**
 * Higher-Order Function that returns a validation function for a given field.
 */
export const createValidator = (
  fieldName: string,
  isRequired: boolean,
  maxLength: number,
  setState: (state: ValidationState) => void
) => {
  return (value: string | undefined | null): boolean => {
    let isError = false;

    console.log(`${fieldName} err`,value, typeof value, value?.length);

    if (isRequired && (!value || value.trim().length === 0)) {
      setState({ state: true, message: `${fieldName} is required.` });
      isError = true;
    } else if (value && value.length > maxLength) {
      setState({
        state: true,
        message: `${fieldName} can be up to ${maxLength} characters. Actual: ${value.length}.`,
      });
      isError = true;
    } else {
      setState({ state: false, message: "" });
    }

    console.log(`isError ${fieldName}`, isError);
    return isError;
  };
};
