import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Paper,
  TableContainer,
} from "@mui/material";
import { ChangeEvent, Dispatch, useCallback, useEffect, useState } from "react";
import { API_BASE_URL, CLIENT_ID } from "../../../utils";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "sonner";
import useUser from "../../../hooks/useUser";
import ItemCard from "../../../components/ItemCard";
import CheckIcon from "@mui/icons-material/Check";
import { businessLogic } from "../../../utils/businessLogic";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";
import { SessionAction, useSession } from "../../../context/SessionProvider";
import { useAuth } from "../../../context/AuthProvider";
import { customer, payment } from "../../../vite-env";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import useWithdrawRequest from "../../../hooks/contentCreator/useWithdrawRequest";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useCreatorBalance from "../../../hooks/useCreatorBalance";
import WorkoutSkeleton from "../../../components/Skeleton";

const WithdrawButton = ({
  AMOUNT_OF_MONEY,
  PAYPAL_EMAIL,
  withdrawMoney,
}: {
  AMOUNT_OF_MONEY: number;
  PAYPAL_EMAIL: string | null;
  withdrawMoney: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleWithdraw = () => {
    withdrawMoney();
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="success" onClick={handleClickOpen}>
        Confirm Withdrawal
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Withdrawal</DialogTitle>
        <DialogContent>
          <p>
            Are you sure you want to withdraw ${AMOUNT_OF_MONEY} to the email{" "}
            {PAYPAL_EMAIL}
            <br />
            There is no turning back.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleWithdraw} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

//!It doesn't accept localhost url
const REDIRECT_URI = `${
  window.location.origin.includes("localhost")
    ? "https://gymobsessive.com"
    : window.location.origin
}/app/settings/creator`;

const environment = import.meta.env.VITE_ENVIRONMENT;

const paypalUrl =
  environment === "production"
    ? "https://www.paypal.com"
    : "https://www.sandbox.paypal.com";

function formatDate(isoString: string) {
  const date = new Date(isoString);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours() % 12 || 12; // Convert to 12-hour format
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const ampm = date.getUTCHours() >= 12 ? "PM" : "AM";

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm} UTC`;
}

const columns: GridColDef[] = [
  { field: "payment_id", headerName: "ID", width: 70 },
  { field: "username", headerName: "User", width: 130 },
  {
    field: "creator_comision",
    headerName: "Earnings",
    width: 90,
    valueGetter: (value) => `$${value}`,
  },
  {
    field: "status",
    headerName: "Status",
    width: 90,
    valueGetter: (value) => (value ? value : "-"),
  },
  {
    field: "created_at",
    headerName: "Date",
    width: 150,
    valueGetter: (value) => formatDate(value),
  },
  /* {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
  }, */
];

const paginationModel = { page: 0, pageSize: 50 };

const withdrawColumns = [
  { field: "withdraw_request_id", headerName: "ID", width: 100 },
  { field: "creator_id", headerName: "Creator ID", width: 130 },
  {
    field: "amount",
    headerName: "Amount",
    width: 100,
    valueGetter: (value: string) => `$${value}`,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    valueGetter: (value: string) => (value ? value : "-"),
  },
  {
    field: "created_at",
    headerName: "Date",
    width: 150,
    valueGetter: (value: string) => formatDate(value),
  },
  {
    field: "paid_at",
    headerName: "Paid Date ",
    width: 150,
    valueGetter: (value: string) => (value ? formatDate(value) : "-"),
  },
];

const paginationModelWithdraw = { page: 0, pageSize: 50 };

export const assignContentCreator = async ({
  creator_id,
  deleteCreator = false,
  silence = false,
  jwt,
  dispatch,
}: {
  creator_id?: string;
  deleteCreator?: boolean;
  silence?: boolean;
  jwt: string | null;
  dispatch: Dispatch<SessionAction> | null | undefined;
}) => {
  if (!jwt || !dispatch) {
    console.log("failed assignContentCreator");
    return;
  }

  try {
    console.log("assign content creator executed");
    const response = await fetch(API_BASE_URL + "/assignContentCreator", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        creator_id,
        deleteCreator, //true for deleting content creator | false to not
      }),
    });
    if (!response.ok) {
      throw new Error("The response was unsuccesfull");
    }
    if (deleteCreator) {
      toast.success("The content creator was deleted succesfully.");
      /*       session.change.creator_id(null);
       */ dispatch!({
        type: "UPDATE_CUSTOMER",
        field: "creator_id",
        value: null,
      });
    } else {
      if (!silence) {
        toast.success("The content creator was assigned succesfully.");
      }
      dispatch!({
        type: "UPDATE_CUSTOMER",
        field: "creator_id",
        value: creator_id,
      });
    }
    console.log("Assign creator result:", response);
    // Handle success, e.g., show a success message or update the UI
  } catch (error) {
    console.error("Error assigning content creator:", error);
    toast.error("The content creator was NOT assigned succesfully.");
  }
};

const Creator = () => {
  const { t } = useTranslation(["creatorSettings"]);
  const navigate = useNavigate();

  const session = useSession();
  const dispatch = session?.dispatch;
  const customer = session?.state?.customer;

  const [search, setSearch] = useState(""); // State to track the search input
  const [creators, setCreators] = useState<null | customer[]>(null); // State to hold the search results

  const { jwt } = useAuth();

  const { balance, unpaidCommissions } = useCreatorBalance();

  console.log("balance, unpaidCommissions: ", balance, unpaidCommissions);

  const { withdrawRequests } = useWithdrawRequest();

  const currentCreatorId = customer?.creator_id;
  const followers_count = customer?.followers_count;
  const is_user_premium = customer?.premium;
  const is_content_creator = customer?.is_content_creator;
  const paypal_email = customer?.paypal_email;

  const { user } = useUser({ user_id: currentCreatorId });

  // Function to handle search input change
  const handleSearchChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);
  };

  // Function to fetch search results from the API
  const fetchCreators = async () => {
    try {
      const response = await fetch(API_BASE_URL + "/searchCreatorsByUsername", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify({ search }),
      });
      const data = await response.json();
      console.log("fetchCreators ", data);
      setCreators(data.data || []); // Set the creators from response
    } catch (error) {
      console.error("Error fetching content creators:", error);
    }
  };

  // Function to assign the selected content creator to the user

  const handleActivateContentCreator = async () => {
    /*
      TODO: Activate content creator
   */
    try {
      const response = await fetch(API_BASE_URL + "/assignContentCreator", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify({
          becomeCreator: true, //true for deleting content creator | false to not
        }),
      });
      if (!response.ok) {
        throw new Error("The response was unsuccesfull");
      }
      toast.success("Congratulations! You are a content creator now!");
      console.log("Assign creator result:", response);
      // Handle success, e.g., show a success message or update the UI
    } catch (error) {
      console.error("Error assigning content creator:", error);
      toast.error("The content creator request was not succesfully.");
    }
  };

  const handleAuthRedirect = () => {
    localStorage.setItem("paypalButtonClicked", "true");

    const paypalAuthUrl = `${paypalUrl}/connect?flowEntry=static&client_id=${CLIENT_ID}&response_type=code&scope=openid profile email&redirect_uri=${REDIRECT_URI}`;
    window.location.href = paypalAuthUrl;
  };

  const handleDeletePaypalEmail = async () => {
    try {
      // Call the backend API to verify PayPal email
      const response = await fetch(
        `${API_BASE_URL}/pay/paypal/verificateEmail`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwt,
          },
          body: JSON.stringify({ deletePaypalEmail: true }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        toast.success("PayPal email successfully deleted.");
        setTimeout(() => {
          window.location.href = "/app/settings/creator";
        }, 1500);
      } else {
        console.error("Error verifying PayPal email:", data.errMsg);
        toast.error("Failed to verify PayPal email.");
      }
    } catch (error) {
      console.error("Error making request to backend:", error);
      toast.error("An error occurred while verifying your PayPal email.");
    }
  };

  const withdrawMoney = async () => {
    //

    try {
      // Call the backend API to verify PayPal email
      const response = await fetch(
        `${API_BASE_URL}/pay/paypal/withdrawBalance`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwt,
          },
        }
      );

      if (response.ok) {
        toast.success(
          "Balance withdraw request made succesfully. Reload in 2s..."
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const data = await response.json();
        console.error("Error withdrawing balance:", data.errMsg);
        toast.error(data.errMsg || "Error withdrawing balance.");
      }
    } catch (error) {
      console.error("An error occurred withdrawing your balance:", error);
      toast.error("An error occurred withdrawing your balance.");
    }
  };

  useEffect(() => {
    // Function to handle the callback and verify email with the backend
    const handlePayPalCallback = async () => {
      // Extract 'code' from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const paypalButtonClicked = localStorage.getItem("paypalButtonClicked");
      //if (code && paypalButtonClicked === "true") {

      if (code && paypalButtonClicked === "true") {
        try {
          // Call the backend API to verify PayPal email
          const response = await fetch(
            `${API_BASE_URL}/pay/paypal/verificateEmail`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + jwt,
              },
              body: JSON.stringify({ code }),
            }
          );

          const data = await response.json();

          if (response.ok) {
            toast.success("PayPal email successfully verified and updated.");
          } else {
            console.error("Error verifying PayPal email:", data.errMsg);
            toast.error("Failed to verify PayPal email.");
          }
        } catch (error) {
          console.error("Error making request to backend:", error);
          toast.error("An error occurred while verifying your PayPal email.");
        }

        // Remove the flag after verification attempt
        localStorage.removeItem("paypalButtonClicked");

        setTimeout(() => {
          window.location.href = "/app/settings/creator";
        }, 1500);
      }
    };

    // Call the function when the component mounts
    handlePayPalCallback();
  }, [navigate, jwt]);

  return (
    <Box
      sx={{ display: "flex", flexWrap: "wrap", gap: 4, pb: 3, width: "100%" }}
    >
      <Box>
        <Typography sx={{ color: "black" }} variant="h4" mt={3}>
          {t("supporCreator")}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          {/* Search Content Creator Feature */}
          {currentCreatorId &&
            (!user ? (
              <CircularProgress size={30} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "0.30rem",
                  boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  p: 1,
                  my: 3,
                }}
              >
                <Typography sx={{ color: "black" }} variant="h4">
                  {t("content")}
                </Typography>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  {user && <ItemCard item={user} />}
                  <Button
                    onClick={() => {
                      assignContentCreator({
                        deleteCreator: true,
                        dispatch,
                        jwt,
                      });
                      setSearch("");
                      setCreators(null);
                    }}
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "55px",
                    }}
                  >
                    {t("delete")}
                  </Button>
                </Box>
              </Box>
            ))}
          {!creators && !currentCreatorId && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                borderRadius: "0.30rem",
                boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                p: 1,
                my: 2,
              }}
            >
              <Typography variant="h5" fontWeight="bold">
                {t("enterUsr")}
              </Typography>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    width: "350px",
                    alignItems: "center",
                  }}
                >
                  {/* Search Input */}
                  <TextField
                    label={t("searchByUsr")}
                    variant="outlined"
                    value={search}
                    onChange={(e) => handleSearchChange(e)}
                    fullWidth
                    sx={{ maxWidth: "300px" }}
                  />

                  {/* Search Button */}
                  <Button
                    onClick={fetchCreators}
                    variant="contained"
                    color="primary"
                    sx={{
                      height: "55px",
                    }}
                  >
                    {t("search")}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          {!currentCreatorId &&
            creators &&
            (creators.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  my: 2,
                  borderRadius: "0.30rem",
                  boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "black" }} variant="h4">
                  {t("selectContent")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    borderRadius: "0.30rem",
                    p: 1,
                    alignItems: "center",
                  }}
                >
                  <CloseIcon
                    sx={{ fontSize: 30, cursor: "pointer " }}
                    onClick={() => {
                      setCreators(null);
                      setSearch("");
                    }}
                  />

                  <Typography sx={{ color: "gray" }}>
                    {t("search")}: {search}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    background: "rgba(0,0,0,0.05)",
                    p: 1,
                  }}
                >
                  {creators &&
                    creators.length > 0 &&
                    creators.map((creator) => (
                      <Box
                        key={creator.customer_id}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event from reaching parent
                          e.preventDefault(); // Prevent any default behavior

                          return assignContentCreator({
                            creator_id: creator.customer_id,
                            jwt,
                            dispatch,
                          });
                        }}
                        sx={{ width: "250px", cursor: "pointer" }}
                      >
                        <Box sx={{ pointerEvents: "none" }}>
                          <ItemCard item={creator} />
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  my: 2,
                  borderRadius: "0.30rem",
                  boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  p: 1,
                }}
              >
                <Typography sx={{ color: "black" }} variant="h4">
                  {t("noCreatorFound")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    borderRadius: "0.30rem",
                    p: 1,
                    alignItems: "center",
                  }}
                >
                  <CloseIcon
                    sx={{ fontSize: 30, cursor: "pointer " }}
                    onClick={() => {
                      setCreators(null);
                      setSearch("");
                    }}
                  />

                  <Typography sx={{ color: "gray" }}>
                    {t("search")}: {search}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
      {is_content_creator ? (
        <Box>
          <Typography sx={{ color: "black" }} variant="h4" mt={3}>
            {t("contentMng")}
          </Typography>
          <Typography sx={{ color: "black" }} variant="h7" mt={3}>
            ({t("minWithdraw")}: $100)
          </Typography>
          <Box
            sx={{
              borderRadius: "0.30rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              p: 1,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "flex-start",
            }}
          >
            {paypal_email && (
              <>
                <Typography sx={{ color: "black" }} variant="h7">
                  {t("payEm")}: {paypal_email}
                </Typography>

                <Button
                  onClick={handleDeletePaypalEmail}
                  variant="contained"
                  sx={{
                    backgroundColor: "#0070E0",
                    color: "white",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    padding: "8px 16px",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img
                    src="/paypal.png"
                    alt="PayPal Logo"
                    width={24}
                    height={24}
                  />
                  {t("deletePay")}
                </Button>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                cursor: "pointer",
                ":hover": {
                  transform: "scale(0.98)",
                },
              }}
              onClick={() => {
                const referralCode = customer.customer_id; // Replace with the actual referral code
                const rootUrl = `${window.location.origin}/app?ref=${referralCode}`;
                navigator.clipboard
                  .writeText(rootUrl)
                  .then(() => {
                    toast.success(t("reffCop")); // Optional: Provide user feedback
                  })
                  .catch((err) => {
                    console.log("err clipboard ", err);
                  });
              }}
            >
              <ContentCopyIcon />

              <Typography sx={{ color: "black" }} variant="h6">
                {t("copyReff")}
              </Typography>
            </Box>
            {is_content_creator && balance === null ? (
              <CircularProgress size={30} />
            ) : (
              <Typography sx={{ color: "black" }} variant="h4">
                {t("balance")}: ${balance}
              </Typography>
            )}

            {paypal_email ? (
              balance && balance >= businessLogic.withdraw.minBalance ? (
                <WithdrawButton
                  AMOUNT_OF_MONEY={balance}
                  PAYPAL_EMAIL={customer?.paypal_email}
                  withdrawMoney={withdrawMoney}
                />
              ) : (
                <Button variant="contained" color="primary" sx={{}}>
                  ${businessLogic.withdraw.minBalance - (balance || 0)}{" "}
                  {t("leftW")}
                </Button>
              )
            ) : (
              <Button
                id="paypal-container-signin"
                onClick={handleAuthRedirect}
                variant="contained"
                sx={{
                  backgroundColor: "#0070E0",
                  color: "white",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "8px 16px",
                  borderRadius: "6px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <img
                  src="/paypal.png"
                  alt="PayPal Logo"
                  width={24}
                  height={24}
                />
                {t("logPay")}
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography sx={{ color: "black" }} variant="h4" mt={3}>
            {t("becomeContent")}
          </Typography>

          <Box
            mt={3}
            sx={{
              borderRadius: "0.30rem",
              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
              p: 1,
            }}
          >
            {followers_count >= businessLogic.contentCreator.minFollowers &&
            is_user_premium ? (
              <>
                <Typography sx={{ color: "black" }} variant="h4">
                  {t("elig")}
                </Typography>
                <Button
                  onClick={() => {
                    handleActivateContentCreator();
                  }}
                  variant="contained"
                  color="primary"
                  sx={{
                    height: "55px",
                  }}
                >
                  {t("ac")}
                </Button>
              </>
            ) : (
              <>
                <Typography sx={{ color: "black" }} variant="h4">
                  {t("req")}:
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  {followers_count >=
                  businessLogic.contentCreator.minFollowers ? (
                    <CheckIcon />
                  ) : (
                    <CloseIcon />
                  )}{" "}
                  {followers_count}/{businessLogic.contentCreator.minFollowers}{" "}
                  {t("follow")}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  {is_user_premium ? <CheckIcon /> : <CloseIcon />}
                  {t("prem")}
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}

      {is_content_creator && (
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ color: "black" }} variant="h4" mt={3}>
            Commissions
          </Typography>
          <Paper sx={{ maxHeight: 400 }}>
            <DataGrid
              rows={unpaidCommissions}
              columns={columns}
              initialState={{ pagination: { paginationModel } }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              sx={{ border: 0 }}
              getRowId={(row) => row.payment_id}
              rowSelection={false}
            />
          </Paper>
        </Box>
      )}

      {is_content_creator && (
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ color: "black" }} variant="h4" mt={3}>
            Withdraw
          </Typography>
          <Paper sx={{ maxHeight: 400 }}>
            <DataGrid
              rows={withdrawRequests}
              columns={withdrawColumns}
              initialState={{
                pagination: { paginationModel: paginationModelWithdraw },
              }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              sx={{ border: 0 }}
              getRowId={(row) => row.withdraw_request_id}
              rowSelection={false}
            />
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default Creator;
