import {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { API_BASE_URL } from "../utils";

import { toast } from "sonner";
import { workout } from "../vite-env";
import { IExerciseWithWorkoutDetails } from "../hooks/useWorkout";
import { IMG_BASE_URL } from "../utils";
import {
  Box,
  Button,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import WorkoutExerciseTableExecution from "../components/WorkoutExerciseTableExecution";
import { postLastEce } from "../utils/exerciseUtils";
import AlarmIcon from "@mui/icons-material/Alarm";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import UploadIcon from "@mui/icons-material/Upload";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation"; // Import navigation styles
import "swiper/css/thumbs"; // Import thumbs styles
import { useTranslation } from "react-i18next";
import { useAuth } from "./AuthProvider";
import { useSession } from "./SessionProvider";

// Define the structure of the workout player state
export interface IWorkoutPlayer {
  display: boolean;
  workout: workout | null;
  exercises: IExerciseWithWorkoutDetails[] | null;
}

// Define possible action types for the reducer
type WorkoutAction =
  | {
      type: "INIT_WORKOUT_PLAYER";
      payload: { workout: workout; exercises: IExerciseWithWorkoutDetails[] };
    }
  | { type: "FINISH_WORKOUT_PLAYER" }
  | {
      type: "UPDATE_EXERCISE_WEIGHT";
      payload: { exerciseIndex: number; setIndex: number; weight: number };
    }
  | {
      type: "UPDATE_EXERCISE_REPS";
      payload: { exerciseIndex: number; setIndex: number; reps: number };
    };

// Define the context structure
interface IWorkoutContext {
  workoutPlayer: IWorkoutPlayer;
  workoutPlayerDispatch: Dispatch<WorkoutAction>;
}

// Initial state
const initialState: IWorkoutPlayer = {
  display: false,
  workout: null,
  exercises: null,
};

// Create context
export const WorkoutContext = createContext<IWorkoutContext | null>(null);

function reducer(state: IWorkoutPlayer, action: WorkoutAction): IWorkoutPlayer {
  switch (action.type) {
    case "INIT_WORKOUT_PLAYER":
      return {
        display: true,
        workout: action.payload.workout,
        exercises: action.payload.exercises,
      };
    case "FINISH_WORKOUT_PLAYER":
      return {
        display: false,
        workout: null,
        exercises: null,
      };
    case "UPDATE_EXERCISE_WEIGHT": {
      const newState = Object.assign({}, state);
      newState.exercises![action.payload.exerciseIndex].execution.seriesDone[
        action.payload.setIndex
      ].weight = action.payload.weight;

      return newState; // React verá un objeto completamente nuevo
    }
    case "UPDATE_EXERCISE_REPS": {
      const newState = Object.assign({}, state);
      newState.exercises![action.payload.exerciseIndex].execution.seriesDone[
        action.payload.setIndex
      ].reps = action.payload.reps;

      return newState; // React will see a completely new object
    }
  }
}

// Define the parameters for executeWorkout
interface ExecuteWorkoutParams {
  workout_id: string;
  jwt: string | null;
  country_id: string | null | undefined;
}

// Execute workout function with strong types
export async function executeWorkout({
  workout_id,
  jwt,
  country_id,
}: ExecuteWorkoutParams): Promise<boolean | null> {
  if (!jwt || !country_id || !workout_id) {
    throw new Error("You need jwt to execute workout");
  }
  console.log("executeWorkout");
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${jwt}`);
    myHeaders.append("Content-Type", "application/json");

    const body = JSON.stringify({ country_id });

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body,
    };

    const res = await fetch(
      `${API_BASE_URL}/executeWorkout/${workout_id}`,
      requestOptions
    );

    if (!res.ok) {
      const resJson = await res.json();
      toast.error(
        resJson.errMsg || "There was an error executing the workout."
      );
      return false;
    }
    console.log("executeWorkout success");
    return true;
  } catch (error) {
    console.error("Error executing workout:", error);
    toast.error("There was an error executing the workout.");
  }
  return null;
}

// Define the props for WorkoutExecutionProvider
interface WorkoutProviderProps {
  children: React.ReactNode;
}

// WorkoutExecutionProvider component
const WorkoutExecutionProvider: React.FC<WorkoutProviderProps> = ({
  children,
}) => {
  const { t } = useTranslation(["workoutTraining"]);

  const { jwt } = useAuth();

  const session = useSession();

  const customer = session?.state.customer;

  const [workoutPlayer, workoutPlayerDispatch] = useReducer(
    reducer,
    initialState
  );
  const [updateState, setUpdateState] = useState<number>(0);
  const [updateInterval, setUpdateInterval] = useState<any>(null);

  // Interval ref
  const interval = useRef<number | null>(null);

  // Process state ref
  const process = useRef<{
    countdown: number | null;
    duration: number | null;
    rest: number | null;
    exerciseCounter: number;
    exerciseCounterMax: number | null;
    stage: string | null;
    workout: workout | null;
    exercises: IExerciseWithWorkoutDetails[] | null;
    time: number | null;
    totalTime: number | null;
    restMax: number | null;
    durationMax: number | null;
    countdownMax: number | null;
    intervalActive?: boolean;
    changedManually?: boolean;
  }>({
    countdown: null,
    duration: null,
    rest: null,
    exerciseCounter: 0,
    exerciseCounterMax: null,
    stage: null,
    workout: null,
    exercises: null,
    time: null,
    totalTime: null,
    restMax: null,
    durationMax: null,
    countdownMax: null,
  });

  console.log("workoutPlayer process", workoutPlayer, process.current);

  useEffect(() => {
    console.log(
      "process init: ",
      workoutPlayer,
      workoutPlayer.workout,
      workoutPlayer.exercises,
      !process.current.stage,
      customer,
      jwt
    );
    if (
      workoutPlayer &&
      workoutPlayer.workout &&
      workoutPlayer.exercises &&
      !process.current.stage &&
      customer &&
      jwt
    ) {
      const totalTime = workoutPlayer.exercises.reduce(
        (acc, curr) => acc + curr.time.totalTime,
        0
      );
      const exArr = workoutPlayer.exercises[process.current.exerciseCounter];

      process.current = {
        ...process.current,
        stage: "countdown",
        exerciseCounterMax: exArr ? workoutPlayer.exercises.length : null,
        rest: exArr.time.rest,
        duration: exArr.time.duration,
        countdown: exArr.time.countdown,
        restMax: exArr.time.rest,
        durationMax: exArr.time.duration,
        countdownMax: exArr.time.countdown,
        time: 0,
        totalTime,
        workout: workoutPlayer.workout,
        exercises: workoutPlayer.exercises,
        intervalActive: true,
        changedManually: false,
      };
      executeWorkout({
        jwt,
        workout_id: workoutPlayer.workout.workout_id,
        country_id: customer?.country_id,
      });
      setUpdateState(Math.random());
    }
  }, [workoutPlayer, customer, jwt]);

  useEffect(() => {
    console.log(
      "use effect interval executed",
      workoutPlayer.workout,
      workoutPlayer.exercises,
      process.current.stage,
      interval.current
    );
    if (
      workoutPlayer &&
      workoutPlayer.workout &&
      workoutPlayer.exercises &&
      process.current.stage
    ) {
      interval.current = setInterval(() => {
        if (process.current.changedManually) {
          process.current = {
            ...process.current,
            changedManually: false,
          };
          return; // Exit this iteration of the callback
        }
        // We increment the time by one second in the process ref NOT IN INTERVAL.
        process.current = {
          ...process.current,
          time: process.current.time + 1,
        };

        // We track the amount of time spent in an exercise in case we want to
        // skip the exercise we should do time = time  + (ex.totalTime - ex.trackTime)
        process.current.exercises[
          process.current.exerciseCounter
        ].time.trackTime =
          process.current.exercises[process.current.exerciseCounter].time
            .trackTime + 1;

        // We track the amount of time spent in an set in case we want to
        // skip the set we should do time = time  + (set.totalTime - set.trackTime)
        process.current.exercises[
          process.current.exerciseCounter
        ].execution.seriesDone[
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesTrack - 1
        ].trackTime =
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesDone[
            process.current.exercises[process.current.exerciseCounter].execution
              .seriesTrack - 1
          ].trackTime + 1;

        if (process.current.stage === "countdown") {
          if (process.current.countdown < 1) {
            process.current = {
              ...process.current,
              stage: "duration",
            };
          } else {
            process.current = {
              ...process.current,
              countdown: process.current.countdown - 1,
            };
          }
        } else if (process.current.stage === "duration") {
          console.log("duration", process.current.duration);

          if (process.current.duration < 1) {
            process.current = {
              ...process.current,
              stage: "rest",
            };
          } else {
            process.current = {
              ...process.current,
              duration: process.current.duration - 1,
            };
          }
        } else if (process.current.stage === "rest") {
          console.log("REST", process.current.rest);
          if (process.current.rest < 1) {
            process.current = {
              ...process.current,
              countdown: process.current.countdownMax,
              rest: process.current.restMax,
              duration: process.current.durationMax,
              stage: "countdown",
            };
            console.log(
              "exerciseCounter execution PREV is :",
              process.current.exercises,
              process.current.exerciseCounter
            );
            let execution =
              process.current.exercises[process.current.exerciseCounter]
                .execution;
            console.log(
              "exerciseCounter execution is :",
              process.current.exerciseCounter,
              execution
            );
            console.log("process.current pre ex", process.current);
            if (execution.seriesTrack >= execution.sets) {
              console.log("execution.seriesTrack >= execution.sets");
              // if there is another exercise
              if (
                process.current.exercises[process.current.exerciseCounter + 1]
              ) {
                process.current = {
                  ...process.current,
                  exerciseCounter: process.current.exerciseCounter + 1,
                  countdown:
                    process.current.exercises[process.current.exerciseCounter]
                      .time.countdownMax,
                  rest: process.current.exercises[
                    process.current.exerciseCounter
                  ].time.restMax,
                  duration:
                    process.current.exercises[process.current.exerciseCounter]
                      .time.durationMax,
                };
              } else {
                process.current = {
                  ...process.current,
                  stage: "success",
                };
                clearInterval(interval.current);
              }
            } else {
              let newObj = Object.assign({}, process.current);
              newObj.exercises[
                process.current.exerciseCounter
              ].execution.seriesTrack =
                newObj.exercises[process.current.exerciseCounter].execution
                  .seriesTrack + 1;
              process.current = {
                ...process.current,
                exercises: newObj.exercises,
              };
            }
          } else {
            process.current = {
              ...process.current,
              rest: process.current.rest - 1,
            };
          }
        }
        console.log("process.current pre render", process.current);

        setUpdateState(Math.random());
      }, 1000);
    }

    return () => {
      console.log("close useEffect", interval.current);
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [
    workoutPlayer,
    workoutPlayer.workout,
    workoutPlayer.exercises,
    updateInterval,
    process.current.stage,
  ]);

  const handleNextSet = () => {
    if (!process.current.exercises) {
      throw new Error("No exercises available.");
    }
    const execution =
      process.current.exercises[process.current.exerciseCounter].execution;

    console.log("process.current pre ex", process.current);

    if (execution.seriesTrack < execution.sets) {
      const timeTrackAuxActualSet =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesDone[
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesTrack - 1
        ].trackTime;

      //Reset set track time
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone[
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesTrack - 1
      ].trackTime = 0;

      //Reset next set track time
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone[
        process.current.exercises[
          process.current.exerciseCounter
        ].execution.seriesTrack
      ].trackTime = 0;

      //Reset time tracking of countdown duration rest
      const exerciseTime =
        process.current.exercises[process.current.exerciseCounter].time;

      const timeToSum =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesDone[
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesTrack - 1
        ].totalTime - timeTrackAuxActualSet;

      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesTrack =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesTrack + 1;

      process.current = {
        ...process.current,
        time: process.current.time + timeToSum + 1,
        stage: "countdown",
        countdown: exerciseTime.countdownMax,
        duration: exerciseTime.durationMax,
        rest: exerciseTime.restMax,
      };

      setUpdateState(Math.random());
    }
  };
  const styleNextSet =
    process.current &&
    process.current.exercises &&
    process.current.exercises[process.current.exerciseCounter].execution
      .seriesTrack <
      process.current.exercises[process.current.exerciseCounter].execution.sets;

  const handlePrevSet = () => {
    if (!process.current.exercises) {
      throw new Error("No exercises available.");
    }
    const execution =
      process.current.exercises[process.current.exerciseCounter].execution;
    console.log(
      "exerciseCounter execution is :",
      process.current.exerciseCounter,
      execution
    );
    console.log("process.current pre ex", process.current);
    /**
     * TODO: compconste prevSet
     */
    if (execution.seriesTrack > 1) {
      const timeTrackAuxActualSet =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesDone[
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesTrack - 1
        ].trackTime;

      const timeTrackAuxPreviousSet =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesDone[
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesTrack - 2
        ].totalTime;

      //Reset set track time
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone[
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesTrack - 1
      ].trackTime = 0;

      //Reset next set track time
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone[
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesTrack - 2
      ].trackTime = 0;

      console.log(
        "test prev set: ",
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesDone[
          process.current.exercises[process.current.exerciseCounter].execution
            .seriesTrack - 2
        ]
      );

      //Reset time tracking of countdown duration rest
      const exerciseTime =
        process.current.exercises[process.current.exerciseCounter].time;

      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesTrack =
        process.current.exercises[process.current.exerciseCounter].execution
          .seriesTrack - 1;

      process.current = {
        ...process.current,
        time:
          process.current.time -
          (timeTrackAuxPreviousSet + timeTrackAuxActualSet),
        stage: "countdown",
        countdown: exerciseTime.countdownMax,
        duration: exerciseTime.durationMax,
        rest: exerciseTime.restMax,
      };

      setUpdateState(Math.random());
    }
  };
  const stylePrevSet =
    process.current &&
    process.current.exercises &&
    process.current.exercises[process.current.exerciseCounter].execution
      .seriesTrack > 1;

  const handleStopTraining = () => {
    process.current = {
      ...process.current,
      intervalActive: false,
    };
    clearInterval(interval.current);
    setUpdateState(Math.random());
  };
  const handleResumeTraining = () => {
    process.current = {
      ...process.current,
      intervalActive: true,
    };
    setUpdateInterval(Math.random());
  };

  const handleCancelTraining = () => {
    const ans = window.confirm(
      "Are you sure to cancel the training? Progress will be lost."
    );
    console.log("ans", ans);
    if (ans) {
      clearInterval(interval.current);
      workoutPlayerDispatch({ type: "FINISH_WORKOUT_PLAYER" });
    }
  };

  const preHandleUploadTraining = () => {
    const ans = window.confirm(
      "Are you sure to upload the training? Timer will stop."
    );
    console.log("ans", ans);
    if (ans) {
      handleStopTraining();
      handleUploadTraining();
    }
  };

  const exerciseTrackVerification = () => {
    if (!workoutPlayer.exercises) return false;
    let valid = true;
    workoutPlayer.exercises.forEach((e) => {
      e.execution.seriesDone.forEach((s) => {
        if (isNaN(s.weight) || s.weight < 0) {
          valid = false;
          toast.error(`Weight can't be lower than 0.`);
        }
        if (isNaN(s.reps) || s.reps < 0) {
          valid = false;
          toast.error(`Reps can't be lower than 0.`);
        }
      });
    });
    return valid;
  };

  const handleUploadTraining = async () => {
    if (!exerciseTrackVerification()) {
      return;
    }
    if (!workoutPlayer.exercises) return false;

    const exercisePromises: Promise<void>[] = [];

    workoutPlayer.exercises.forEach((e) => {
      const obj: {
        [key: string]: {
          weight: number;
          totalReps: number;
          count: number;
          exercise_id: string;
        };
      } = {}; // Object to group series by weight

      e.execution.seriesDone.forEach((serDoneEl) => {
        const weight = serDoneEl.weight;
        const reps = serDoneEl.reps;
        if (reps < 1) {
          return; // This acts like 'continue' in the `forEach` loop
        }
        // If the weight group exists, accumulate reps
        if (obj[weight]) {
          obj[weight].totalReps += reps;
          obj[weight].count += 1;
        } else {
          // Initialize group for this weight
          obj[weight] = {
            weight: weight,
            totalReps: reps, // Start with the current reps
            count: 1,
            exercise_id: e.exercise_id,
          };
        }
      });

      console.log("obj is upload ece: ", obj);

      // After grouping, calculate the average reps for each weight group
      Object.keys(obj).forEach((weight) => {
        const group = obj[weight];
        const averageReps = group.totalReps / group.count;

        const finalObj = {
          jwt,
          weight: group.weight,
          reps: averageReps, // Average reps for this weight
          exercise_id: group.exercise_id,
        };

        console.log("Grouped exercise obj: ", finalObj);

        // Push the post request promise
        exercisePromises.push(postLastEce(finalObj));
      });
    });

    try {
      const res = await Promise.all(exercisePromises);
      console.log("training ", res);
      toast.success("The training was uploaded succesfully");
      clearInterval(interval.current);
      interval.current = null;
      workoutPlayerDispatch({ type: "FINISH_WORKOUT_PLAYER" });
    } catch (error) {
      toast.error("The training was not uploaded succesfully. Try again later");

      console.log("training ERROR ", error);
    }
  };

  const rows = workoutPlayer.exercises;
  const stage = process.current.stage;
  const countdown = process.current.countdown;
  const duration = process.current.duration;
  const rest = process.current.rest;
  const countdownMax = process.current.countdownMax;
  const durationMax = process.current.durationMax;
  const restMax = process.current.restMax;
  const exerciseCounter = process.current.exerciseCounter;

  /**
   * I'm going to handle the next and previous exercise logic with the styles too.
   */
  const handlePreviousExercise = () => {
    if (!process.current.exercises) {
      throw new Error("exercises required");
    }
    if (process.current.exercises[process.current.exerciseCounter - 1]) {
      /**
       * TODO: CLEAR ACTUAL EXERCISE AND PREVIOUS EXERCISE TIME AND EXECTION | UPDATE PROCESS.COUN.DUR.REST TO THE PREVIOUYS
       */
      const timeTrackAuxPreviousEx =
        process.current.exercises[process.current.exerciseCounter - 1].time
          .totalTime;

      const timeTrackAuxActualEx =
        process.current.exercises[process.current.exerciseCounter].time
          .trackTime;

      //Reset previous exercise
      const prevTimeEx =
        process.current.exercises[process.current.exerciseCounter - 1].time;

      //Reset exercise time in set track
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone = process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone.map((e) => ({ ...e, trackTime: 0 }));

      //Reset exercise time in set track in following
      process.current.exercises[
        process.current.exerciseCounter - 1
      ].execution.seriesDone = process.current.exercises[
        process.current.exerciseCounter - 1
      ].execution.seriesDone.map((e) => ({ ...e, trackTime: 0 }));

      process.current.exercises[
        process.current.exerciseCounter - 1
      ].execution.seriesTrack = 1;
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesTrack = 1;

      //Reset actual exercise time
      process.current.exercises[process.current.exerciseCounter].time = {
        ...process.current.exercises[process.current.exerciseCounter].time,
        trackTime: 0,
      };
      //Reset previous exercise time
      process.current.exercises[process.current.exerciseCounter - 1].time = {
        ...process.current.exercises[process.current.exerciseCounter - 1].time,
        trackTime: 0,
      };

      process.current = {
        ...process.current,
        exerciseCounter: process.current.exerciseCounter - 1,
        time:
          process.current.time -
          (timeTrackAuxPreviousEx + timeTrackAuxActualEx),
        stage: "countdown",
        countdown: prevTimeEx.countdownMax,
        duration: prevTimeEx.durationMax,
        rest: prevTimeEx.restMax,
      };

      setUpdateState(Math.random());
    }
  };
  const previousExerciseStyle =
    process.current &&
    process.current.exerciseCounter &&
    process.current.exercises[process.current.exerciseCounter - 1] !== undefined
      ? true
      : false;

  const handleNextExercise = () => {
    if (!process.current.exercises) {
      throw new Error("exercises required");
    }
    if (process.current.exercises[process.current.exerciseCounter + 1]) {
      const timeTrackAuxActualEx =
        process.current.exercises[process.current.exerciseCounter].time
          .trackTime;

      const nextTimeEx =
        process.current.exercises[process.current.exerciseCounter + 1].time;

      //Reset exercise track time
      process.current.exercises[process.current.exerciseCounter].time = {
        ...process.current.exercises[process.current.exerciseCounter].time,
        trackTime: 0,
      };
      //Reset exercise track time
      process.current.exercises[process.current.exerciseCounter + 1].time = {
        ...process.current.exercises[process.current.exerciseCounter + 1].time,
        trackTime: 0,
      };

      //Reset exercise time in set track
      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone = process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesDone.map((e) => ({ ...e, trackTime: 0 }));

      //Reset exercise time in set track in following
      process.current.exercises[
        process.current.exerciseCounter + 1
      ].execution.seriesDone = process.current.exercises[
        process.current.exerciseCounter + 1
      ].execution.seriesDone.map((e) => ({ ...e, trackTime: 0 }));

      process.current.exercises[
        process.current.exerciseCounter
      ].execution.seriesTrack = 1;

      process.current.exercises[
        process.current.exerciseCounter + 1
      ].execution.seriesTrack = 1;

      const timeToSum =
        process.current.exercises[process.current.exerciseCounter].time
          .totalTime - timeTrackAuxActualEx;

      process.current = {
        ...process.current,
        exerciseCounter: process.current.exerciseCounter + 1,
        time: process.current.time + timeToSum,
        stage: "countdown",

        countdown: nextTimeEx.countdownMax,
        duration: nextTimeEx.durationMax,
        rest: nextTimeEx.restMax,
      };
      console.log(
        "process.current.exercises[process.current.exerciseCounter] clear",
        process.current.exercises[process.current.exerciseCounter]
      );
      setUpdateState(Math.random());
    }
  };
  const nextExerciseStyle =
    process.current &&
    process.current.exercises &&
    process.current.exercises[process.current.exerciseCounter + 1]
      ? true
      : false;

  return (
    <WorkoutContext.Provider value={{ workoutPlayer, workoutPlayerDispatch }}>
      {children}
      {workoutPlayer.workout && process.current && (
        <Box
          sx={{
            width: "100%",
            height: "100dvh",
            backgroundColor: "white",
            position: "fixed",
            display: "column",
            overflowY: "auto",
          }}
        >
          <LinearProgress
            variant="determinate"
            value={(process.current.time / process.current.totalTime) * 100}
            sx={{ mb: 1, position: "sticky", top: 0, zIndex: 150 }}
            color="inherit"
          />

          <Typography
            sx={{
              fontSize: { xs: 30, sm: 50 },
              mb: { xs: 2, sm: 5 },
              textAlign: "center",
            }}
            pl={2}
            fontWeight="bold"
          >
            {t("workTra")}
          </Typography>
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box
                component="img"
                src={IMG_BASE_URL + workoutPlayer.workout.photo_url}
                sx={{
                  height: "140px",
                  width: "140px",
                  maxHeight: "140px",
                  maxWidth: "140px",
                  mb: 1,
                  borderRadius: "0.30rem",
                  boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  objectFit: "cover",
                }}
              ></Box>
              <Box>
                <Typography variant="h4" fontWeight={"bold"}>
                  {workoutPlayer.workout.name}
                </Typography>
                <Typography
                  sx={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  {Math.round((process.current.totalTime / 60) * 100) / 100} Min
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 1,
                justifyContent: "center",
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 3, sm: 0 },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  background: "black",
                  color: "white",
                  height: "3rem",
                  ":hover": { background: "black", color: "white" },
                  width: "100px",
                }}
                onClick={handleCancelTraining}
              >
                {t("cancel")} <TrendingDownIcon fontSize="medium" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  background: "black",
                  color: "white",
                  height: "3rem",
                  ":hover": { background: "black", color: "white" },
                  paddingX: 0,
                  width: "100px",
                }}
                onClick={preHandleUploadTraining}
              >
                {t("upload")} <TrendingUpIcon fontSize="medium" />
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              p: 2,
            }}
          >
            {workoutPlayer.workout.description && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography fontWeight={"bold"}>{t("desc")}</Typography>
                <Typography
                  sx={{
                    whiteSpace: "pre-line",
                    maxHeight: "200px",
                    overflowY: "auto",
                    px: 1,
                  }} // Handles \n as line breaks
                >
                  {workoutPlayer.workout.description}
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ display: { xs: "none", sm: "flex" }, p: 1 }}>
            {workoutPlayer.exercises && (
              <WorkoutExerciseTableExecution
                rows={rows}
                workoutPlayerDispatch={workoutPlayerDispatch}
                workoutPlayer={workoutPlayer}
                stage={stage}
                countdown={countdown}
                duration={duration}
                rest={rest}
                countdownMax={countdownMax}
                durationMax={durationMax}
                restMax={restMax}
                exerciseCounter={exerciseCounter}
              />
            )}
          </Box>
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              flexDirection: "column",
              mt: { xs: 2, sm: 5 },
              rowGap: 2,
              p: 1,
            }}
          >
            {workoutPlayer.exercises && workoutPlayer.exercises.length > 0 ? (
              workoutPlayer.exercises.map((row, photoIndex) => {
                return (
                  <Box
                    key={row.exercise_id}
                    sx={{
                      gap: 1,
                      display: "flex",
                      flexDirection: "column",
                      "&:last-child td, &:last-child th": { border: 0 },
                      borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                      background:
                        photoIndex === exerciseCounter
                          ? "rgba(0,0,0,0.06)"
                          : "",
                    }}
                  >
                    {photoIndex === exerciseCounter && (
                      <Box sx={{ position: "relative", overflow: "hidden" }}>
                        <Box sx={{ overflow: "hidden" }}>
                          <LinearProgress
                            variant="determinate"
                            value={
                              stage === "countdown"
                                ? (countdown / countdownMax) * 100
                                : stage === "duration"
                                ? (duration / durationMax) * 100
                                : (rest / restMax) * 100
                            }
                            color={
                              stage === "countdown"
                                ? "warning"
                                : stage === "duration"
                                ? "error"
                                : "info"
                            }
                          />
                        </Box>
                      </Box>
                    )}

                    <Box sx={{ display: "flex", p: 1, gap: 1 }}>
                      {/* Media Slider */}
                      <Box>
                        <Swiper
                          modules={[Navigation, Pagination]}
                          pagination={{ clickable: true }}
                          spaceBetween={10}
                          slidesPerView={1}
                          style={{
                            width: "150px",
                            height: "150px",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "0.3rem",
                            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.15)",
                          }}
                        >
                          {/* Map through media (images and video) */}
                          {[
                            ...(row.photo_arr ? row.photo_arr : []),
                            ...(row.video_arr ? [row.video_arr[0]] : []),
                          ].map((item, index) => (
                            <SwiperSlide key={index}>
                              {item.includes("video") ? (
                                <Box
                                  component="video"
                                  controls
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    zIndex: -1,
                                  }}
                                >
                                  <source
                                    src={IMG_BASE_URL + item}
                                    type="video/mp4"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                  Your browser does not support the video tag.
                                </Box>
                              ) : (
                                <Box
                                  component="img"
                                  src={IMG_BASE_URL + item}
                                  alt={`Media ${index}`}
                                  sx={{
                                    width: "150px",
                                    height: "150px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Box>

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="h5">
                          {photoIndex + 1 + ") "} {row.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          <Typography>{row.execution.sets} sets</Typography> ·
                          <Typography>RIR {row.execution.rir}</Typography> ·
                          <Typography>
                            {row.execution.reps.min === row.execution.reps.max
                              ? row.execution.reps.min
                              : `${row.execution.reps.min} - ${row.execution.reps.max}`}{" "}
                            Reps
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        maxHeight: 60,
                        overflowY: "auto",
                        p: 1,
                      }}
                    >
                      <Typography> {row.execution.details}</Typography>
                    </Box>
                    {/* TODO: CONVERT TIMER TO ICON TO BE EASIER TO READ TO CUSTOMER
                     */}
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-around",
                        alignItems: "center",
                        gap: 2,
                        p: 1,
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          color={
                            photoIndex === exerciseCounter &&
                            stage === "countdown"
                              ? "warning"
                              : "primary"
                          }
                          display="flex"
                          alignItems="center"
                          gap={1}
                          height={25}
                        >
                          <AlarmIcon />
                          {photoIndex === exerciseCounter &&
                          stage === "countdown"
                            ? countdown
                            : row.time.countdown}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          color={
                            photoIndex === exerciseCounter &&
                            stage === "duration"
                              ? "error"
                              : "primary"
                          }
                          display="flex"
                          alignItems="center"
                          gap={1}
                          p={1}
                          height={25}
                        >
                          <TimelapseIcon />
                          {photoIndex === exerciseCounter &&
                          stage === "duration"
                            ? duration
                            : row.time.duration}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex" }}>
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          color={
                            photoIndex === exerciseCounter && stage === "rest"
                              ? "info"
                              : "primary"
                          }
                          display="flex"
                          alignItems="center"
                          gap={1}
                          height={25}
                        >
                          <BedtimeIcon />
                          {photoIndex === exerciseCounter && stage === "rest"
                            ? rest
                            : row.time.rest}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        flexDirection: "column",
                        width: "100%",
                        justifyContent: "space-around",
                        p: 1,
                        pb: 2,
                        overflowX: "auto",
                      }}
                    >
                      {new Array(rows[photoIndex].execution.sets)
                        .fill(1)
                        .map((e, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              gap: 1,
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderRadius: "0.30rem",
                              boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                              background: "rgba(0,0,0,0.1)",
                              border:
                                rows[photoIndex].execution.seriesTrack ===
                                  index + 1 && "1px solid black",

                              p: 1,
                            }}
                          >
                            <Typography
                              color="white"
                              textAlign={"center"}
                              variant="h6"
                              sx={{
                                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", // Add black shadow with slight blur
                              }}
                            >
                              Set {index + 1}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                              }}
                            >
                              <TextField
                                sx={{
                                  zIndex: 0,

                                  background: "white",
                                  width: "90px",
                                }}
                                defaultValue={
                                  workoutPlayer.exercises[photoIndex].execution
                                    .seriesDone[index].weight
                                }
                                onChange={(e) => {
                                  const weight = parseInt(e.target.value);
                                  if (!isNaN(weight)) {
                                    workoutPlayerDispatch({
                                      type: "UPDATE_EXERCISE_WEIGHT",
                                      payload: {
                                        exerciseIndex: photoIndex,
                                        setIndex: index,
                                        weight,
                                      },
                                    });
                                  }
                                }}
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{ marginLeft: 1 }}
                                    >
                                      Kg
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <TextField
                                color="primary"
                                sx={{
                                  width: "90px",
                                  background: "white",
                                }}
                                defaultValue={
                                  workoutPlayer.exercises[photoIndex].execution
                                    .seriesDone[index].reps
                                }
                                onChange={(e) => {
                                  const reps = parseInt(e.target.value);
                                  if (!isNaN(reps)) {
                                    workoutPlayerDispatch({
                                      type: "UPDATE_EXERCISE_REPS",
                                      payload: {
                                        exerciseIndex: photoIndex,
                                        setIndex: index,
                                        reps,
                                      },
                                    });
                                  }
                                }}
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{ marginLeft: 1 }}
                                    >
                                      Rep
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Typography textAlign={"center"} color={"rgba(0,0,0,0.4)"}>
                No content
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              position: "sticky",
              bottom: "0",
              background: "rgba(255, 255, 255, 0.4)", // Slightly transparent white background
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              backdropFilter: "blur(10px)", // Apply blur effect to the background
              WebkitBackdropFilter: "blur(10px)", // Safari support
              zIndex: 100,
            }}
          >
            {process.current.stage === "success" ? (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  background: "black",
                  color: "white",
                  height: "3rem",
                  ":hover": { background: "black", color: "white" },

                  gap: 0.5,
                  my: 1,
                }}
                onClick={handleUploadTraining}
              >
                <UploadIcon /> Upload training
              </Button>
            ) : (
              <>
                <SkipPreviousIcon
                  fontSize="large"
                  sx={{
                    width: "40px",
                    height: "40px",
                    ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    opacity: stylePrevSet ? "" : "0.5",
                    borderRadius: 100,
                    p: 0.5,
                    boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  }}
                  onClick={handlePrevSet}
                />
                <SkipPreviousIcon
                  fontSize="large"
                  sx={{
                    width: "50px",
                    height: "50px",
                    ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    opacity: previousExerciseStyle ? "" : "0.5",
                    borderRadius: 100,
                    p: 0.5,
                    boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  }}
                  onClick={handlePreviousExercise}
                />
                {process.current.intervalActive ? (
                  <PauseCircleIcon
                    sx={{
                      width: "60px",
                      height: "60px",
                      ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    }}
                    onClick={handleStopTraining}
                  />
                ) : (
                  <PlayCircleIcon
                    sx={{
                      width: "60px",
                      height: "60px",
                      ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    }}
                    onClick={handleResumeTraining}
                  />
                )}
                <SkipNextIcon
                  fontSize="large"
                  sx={{
                    width: "50px",
                    height: "50px",
                    ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    opacity: nextExerciseStyle ? "" : "0.5",
                    borderRadius: 100,
                    p: 0.5,
                    boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  }}
                  onClick={handleNextExercise}
                />
                <SkipNextIcon
                  fontSize="large"
                  sx={{
                    width: "40px",
                    height: "40px",
                    ":hover": { cursor: "pointer", transform: "scale(0.9)" },
                    opacity: styleNextSet ? "" : "0.5",
                    borderRadius: 100,
                    p: 0.5,
                    boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                  }}
                  onClick={handleNextSet}
                />
              </>
            )}
          </Box>
        </Box>
      )}
    </WorkoutContext.Provider>
  );
};

export default WorkoutExecutionProvider;

// Custom hook to handle workout execution
export function useWorkoutExecution() {
  const context = useContext(WorkoutContext);

  if (!context) {
    throw new Error(
      "useWorkoutExecution must be used within a WorkoutExecutionProvider"
    );
  }

  return context;
}
