import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { IMG_BASE_URL } from "../../utils";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { TradingGraphStages } from "../TradingGraphStages";
import TradingGraph from "../TradingGraph/index.jsx";
import { useTranslation } from "react-i18next";

const HomeCard = memo(
  ({
    customerData,
    Icon,
    name,
    to,
    viewOnly = true,
    graph = true,
    workout,
    grapType = "line",
    RenderChildren = false,
    isExercise,
    children,
    exercise,
    percentageChange,
  }) => {
    const { t } = useTranslation(["compHomeCard"]);
    const navigate = useNavigate();

    return (
      <Box
        key={name}
        sx={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          minWidth: "120px",
          minHeight: "100px",
          width: exercise ? "180px" : "140px",
          height: exercise ? "100px" : "140px",
          boxShadow: "0 25px 50px 0px rgba(0,0,0,0.15)",
          borderRadius: 3,
          color: "black",
          p: 1,
          textDecoration: "none",
          position: "relative", // Ensure the overlay is positioned correctly
          ":hover": {
            transform: "scale(0.99)",
            cursor: "pointer",
          },
        }}
        onClick={() => navigate(to)}
      >
        <Box sx={{ width: "100%", display: "flex", gap: 1 }}>
          {exercise && (
            <Box
              component="img"
              sx={{
                height: "50px",
                width: "50px",
                bgcolor: "rgba(0,0,0,0.1)",
                borderRadius: "0.30rem",
                boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                objectFit: "cover",
              }}
              src={IMG_BASE_URL + exercise.photo_arr[0]}
              alt="preview"
            ></Box>
          )}
          <Typography
            variant={isExercise ? "p" : "h6"}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.3,
              fontWeight: isExercise && "bold",
            }}
          >
            {Icon && <Icon fontSize="medium" />} {name}{" "}
            {percentageChange && String(parseInt(percentageChange) + "%")}
          </Typography>
        </Box>

        {graph ? (
          customerData && customerData.length > 0 ? (
            <>
              {grapType === "line" ? (
                <TradingGraph
                  data={customerData}
                  rightPriceScale={false}
                  leftPriceScale={false}
                  timeScaleVisible={false}
                />
              ) : (
                <TradingGraphStages
                  phases={customerData}
                  timeScaleVisible={false}
                />
              )}

              {viewOnly && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 100,
                  }}
                />
              )}
            </>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                mr={0.5}
                fontBold={"bold"}
                textAlign={"center"}
              >
                {t("add")}
              </Typography>
              <AddCircleOutlineIcon />
            </Box>
          )
        ) : RenderChildren !== false ? (
          children
        ) : (
          <Box
            component="img"
            src={IMG_BASE_URL + workout.photo_url}
            sx={{
              width: "100%",
              height: "100%",
              bgcolor: "rgba(0,0,0,0.1)",
              objectFit: "cover",
            }}
          />
        )}
      </Box>
    );
  }
);

export default HomeCard;
