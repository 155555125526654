import useSWR from "swr";
import { fetchLastEce } from "../utils/exerciseUtils";
import { useAuth } from "../context/AuthProvider";

const useCustomerExercisesExecution = ({
  customer_id,
}: {
  customer_id: string | undefined;
}) => {
  const { jwt } = useAuth();

  const { data, isLoading, error, isValidating } = useSWR(
    { url: "customerExercisesArr", customer_id, jwt },
    () => fetchLastEce({ jwt, customer_id })
  );

  return {
    customerExercisesArr: data?.customerExercisesArr,
    isLoading,
    isValidating,
    error,
    settingsCustomerExercisesArr: data?.settingsCustomerExercisesArr,
  };
};

export default useCustomerExercisesExecution;
