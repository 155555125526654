import { Box, Button, Skeleton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import ItemCard from "../ItemCard";
import CancelIcon from "@mui/icons-material/Cancel";
import PlaceIcon from "@mui/icons-material/Place";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonIcon from "@mui/icons-material/Person";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TodayIcon from "@mui/icons-material/Today";

import PublicIcon from "@mui/icons-material/Public";
import { useTranslation } from "react-i18next";
import { useSession } from "../../context/SessionProvider";

const ItemsWithFilter = () => {
  const { t } = useTranslation(["compItemsWithFilter"]);

  const session = useSession();
  const state = session?.state ?? null;

  const customer = state?.customer ?? null;
  const allTables = state?.allTables ?? null;

  const navigate = useNavigate();

  const [isFilter, setIsFilter] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;
  console.log("currentPath: ", currentPath);

  const [filter, setFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const filteredItems =
    allTables &&
    allTables?.length > 0 &&
    allTables?.filter(
      (item) =>
        item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.username?.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: "0.30rem",
        boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none", // For Chrome, Safari, and Edge
        },
        px: 0.5,
      }}
    >
      <Box
        sx={{
          ml: { xs: 0, md: 1 },
          display: "flex",
          justifyContent: { xs: "center", md: "flex-start" },
          alignItems: "center",
        }}
      >
        <Box
          onClick={() => {
            navigate("/app/library");
          }}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
            alignItems: "center",

            ":hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
        >
          <LibraryBooksIcon />
          <Typography
            component="h1"
            variant="h9"
            mx={1}
            sx={{
              display: {
                xs: "none",
                md: "flex",
                display: { xs: "none", md: "flex" },
              },
            }}
          >
            {t("library")}
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          {isFilter ? (
            <FilterAltIcon
              onClick={() => {
                setIsFilter(false);
              }}
              fontSize="medium"
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            />
          ) : (
            <FilterAltOffIcon
              fontSize="medium"
              onClick={() => {
                setIsFilter(true);
              }}
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1, width: "100%", p: 1 }}>
        <Box
          sx={{
            width: "50%",
            minHeight: "40px",
            borderRadius: "0.30rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            display: "flex",
            textTransform: "none",
            alignItems: "center",
            justifyContent: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            p: 1,
            gap: 0.5,

            color: "black",
            ":hover": {
              bgcolor: "rgba(0,0,0,0.02)",
              cursor: "pointer",
              transform: "scale(0.95)",
            },
            ":focus": {
              color: "black",
            },
            ":active": {
              color: "black",
            },
            textDecoration: "none",
          }}
          onClick={() => {
            navigate(
              "/app/top50mostExecutedWorkoutsCountry/" + customer.country_id
            );
          }}
        >
          <PlaceIcon fontSize="large" />
          <Typography sx={{ display: { xs: "none", md: "flex" } }}>
            {customer?.country_name && customer?.country_name.slice(0, 3)}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "50%",
            minHeight: "40px",
            borderRadius: "0.30rem",
            boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
            display: "flex",
            textTransform: "none",
            alignItems: "center",
            justifyContent: "center",
            gap: 0.5,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            p: 1,
            color: "black",
            ":hover": {
              bgcolor: "rgba(0,0,0,0.02)",
              cursor: "pointer",
              transform: "scale(0.95)",
            },
            ":focus": {
              color: "black",
            },
            ":active": {
              color: "black",
            },
            textDecoration: "none",
          }}
          onClick={() => {
            navigate("/app/Top50WorldPopularWorkouts");
          }}
        >
          <PublicIcon fontSize="large"></PublicIcon>
          <Typography sx={{ display: { xs: "none", md: "flex" } }}>
            {t("global")}
          </Typography>
        </Box>
      </Box>
      {isFilter && (
        <Box sx={{ p: 1, gap: 1 }}>
          <Box
            sx={{
              width: "100%",
              gap: 1,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              mb: 1,
              position: "relative",
            }}
          >
            <Button
              onClick={() => {
                setFilter("exercise");
              }}
              variant={filter === "exercise" ? "outlined" : ""}
              sx={{
                textTransform: "none",
                borderRadius: "0.30rem",
                backgroundColor: "rgba(0,0,0,0.05)",
              }}
            >
              <FitnessCenterIcon fontSize="medium" />
            </Button>
            <Button
              onClick={() => {
                setFilter("workout");
              }}
              variant={filter === "workout" ? "outlined" : ""}
              sx={{
                textTransform: "none",
                borderRadius: "0.30rem",
                backgroundColor: "rgba(0,0,0,0.05)",
              }}
            >
              <ListAltIcon fontSize="medium" />
            </Button>
            <Button
              onClick={() => {
                setFilter("user");
              }}
              variant={filter === "user" ? "outlined" : ""}
              sx={{
                textTransform: "none",
                borderRadius: "0.30rem",
                backgroundColor: "rgba(0,0,0,0.05)",
              }}
            >
              <PersonIcon fontSize="medium" />
            </Button>
            <Button
              onClick={() => {
                setFilter("routine");
              }}
              variant={filter === "routine" ? "outlined" : ""}
              sx={{
                textTransform: "none",
                borderRadius: "0.30rem",
                backgroundColor: "rgba(0,0,0,0.05)",
              }}
            >
              <TodayIcon fontSize="medium" />
            </Button>
            <CancelIcon
              sx={{
                ":hover": { cursor: "pointer", transform: "scale(0.95)" },
                position: "absolute",
                top: -10,
                right: -5,
                display: filter === "" && "none",
              }}
              onClick={() => {
                console.log(false);
                setIsFilter(false);
                setFilter("");
                setSearchTerm("");
              }}
            />
          </Box>
          <TextField
            label="Search Items"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
      )}

      {!allTables &&
        Array(20)
          .fill("")
          .map((e, i) => (
            <Box
              key={i}
              sx={{
                width: "100%",
                minHeight: "70px",
                borderRadius: "0.30rem",
                boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                gap: 1,
                mb: 1,
                p: 1,
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Skeleton
                variant="rounded"
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "0.30rem",
                  boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
                }}
              ></Skeleton>
              <Box
                sx={{
                  width: "70%",
                  height: "100%",
                  display: { xs: "none", md: "flex" },
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Skeleton animation="wave" height={20} sx={{ mb: 1 }} />
                <Skeleton animation="wave" height={13} width="80%" />
              </Box>
            </Box>
          ))}

      {filteredItems &&
        filteredItems
          .filter((item) => {
            if (filter === "workout") {
              return "workout" === item.type;
            } else if (filter === "exercise") {
              return "exercise" === item.type;
            } else if (filter === "user") {
              return "user" === item.type;
            } else if (filter === "routine") {
              return "routine" === item.type;
            }
            return true;
          })
          .map((item) => <ItemCard item={item} key={JSON.stringify(item)} />)}
      {allTables && Array.isArray(allTables) && allTables.length === 0 && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              alignItems: "center",
              textAlign: "center",
              flexWrap: "wrap",
            }}
            onClick={() => navigate("/app/create/workout")}
          >
            <Typography fontWeight="bold">{t("createWork")}</Typography>

            <LibraryAddIcon sx={{ marginLeft: 1 }} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ItemsWithFilter;
